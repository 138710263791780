import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { appleSignIn, auth, googleSignIn, googleSignOut, initializePushNotifications } from './firebase';
import { getAdditionalUserInfo, onAuthStateChanged, User } from 'firebase/auth';
import Routes, { Route } from './Routes';
import { Storage } from './components/Impersonation';
import { Metric, unsetMetricUser, setMetricUser, trackMetric } from './utils/metrics';
import BigSpinner from './components/BigSpinner';
import ScrollToTop from './components/ScrollToTop';
import * as Sentry from '@sentry/react';
import upsertUser from './utils/upsertUser';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './css/all.scss';
import ErrorPage from './pages/ErrorPage';
import AuthContext from './contexts/AuthContext';

// Note: next.spend.money and localhost:5000 are considered "production."
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export default function App() {
  return (
    <ErrorBoundary fallbackRender={({ error }) => <ErrorPage error={error} />}>
      <AuthProvider>
        <ScrollToTop />
        <Routes />
      </AuthProvider>
    </ErrorBoundary>
  );
}

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [impersonating, setImpersonating] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        setMetricUser(authUser);
        setImpersonating(sessionStorage.getItem(Storage.Impersonating));

        console.log('initializing push notifications');
        await initializePushNotifications(authUser.uid);

        await upsertUser(authUser);

        Sentry.setUser({ id: authUser.uid });
      } else {
        unsetMetricUser();
      }

      setUser(authUser);
      setIsLoading(false);
    });
  }, []);

  async function handleCredential(userCredential) {
    const additionalUserInfo = getAdditionalUserInfo(userCredential);
    trackMetric(additionalUserInfo?.isNewUser ? Metric.SIGN_UP : Metric.LOG_IN);
  }

  const signInWithGoogle = async () => {
    if (user) return;

    const userCredential = await googleSignIn();
    await handleCredential(userCredential);
  };

  const signInWithApple = async () => {
    if (user) return;

    const userCredential = await appleSignIn();
    await handleCredential(userCredential);
  };

  const signOut = async () => {
    await googleSignOut();
    sessionStorage.removeItem(Storage.Impersonating);
    navigate(Route.Root);
  };

  if (isLoading) return <BigSpinner />;

  return (
    <AuthContext.Provider
      value={{
        uid: user?.uid,
        user,
        signInWithApple,
        signInWithGoogle,
        signOut,
        impersonating,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
