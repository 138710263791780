import React, { useState } from 'react';
import usePlans from '../../hooks/usePlans';
import { sortBy } from 'underscore';
import PlanRow from '../Plans/PlanRow';
import { PlanDocData } from '../../types';
import { useDashboard } from '../../contexts/DashboardContext';
import { Timescale } from '../../shared/BudgetCalendar';
import DollarAmount from '../DollarAmount';
import SectionButton from './SectionButton';
import { BsClipboardData, BsPiggyBank } from 'react-icons/bs';
import { LABELS } from '../../shared/constants';
import BetterModal from '../BetterModal';
import { useInstallments } from '../../hooks/useInstallments';
import ErrorAlert from '../ErrorAlert';
import NoContent from '../NoContent';
import NewGoalButton from '../Plans/NewGoalButton';
import dayjs from 'dayjs';
import { useAppLayout } from '../../contexts/AppLayoutContext';

export default function RecoveriesAndGoals() {
  const { budgetPeriods, timescale } = useAppLayout();
  const { budget } = useDashboard();
  const [isRecoveriesExpanded, setIsRecoveriesExpanded] = useState(false);
  const [isGoalsExpanded, setIsGoalsExpanded] = useState(false);

  const { installments: allInstallments, isLoading: isInstallmentsLoading, error: installmentsError } = useInstallments({});
  const { goals: allGoals, recoveries: allRecoveries, isLoading: isPlansLoading, error: plansError } = usePlans();

  const planIdsForPeriod = allInstallments
    .filter(
      (installment) =>
        dayjs(installment.date.toDate()).isSameOrAfter(budgetPeriods.utilization.start) &&
        dayjs(installment.date.toDate()).isBefore(budgetPeriods.utilization.end),
    )
    .map((installment) => installment.planId);
  const filterPlansToPeriod = (plan) => planIdsForPeriod.includes(plan.__id);
  const goalsForPeriod = allGoals.filter(filterPlansToPeriod);
  const completedGoals = allGoals
    .filter((plan) => !planIdsForPeriod.includes(plan.__id))
    .filter((goal) => goal.installmentsSum >= goal.targetAmount);
  const recoveries = allRecoveries.filter(filterPlansToPeriod);

  let recoveriesSum = 0;
  let goalsSum = 0;

  if (budget) {
    recoveriesSum = timescale === Timescale.Week ? budget.weekAmortizationsSum : budget.monthAmortizationsSum;
    goalsSum = timescale === Timescale.Week ? budget.weekGoalsSum : budget.monthGoalsSum;
  }

  const isLoading = !budget || isPlansLoading || isInstallmentsLoading;

  return (
    <>
      <SectionButton
        label={LABELS.RECOVERIES}
        secondColumn={<DollarAmount amount={recoveriesSum} color />}
        onClick={() => setIsRecoveriesExpanded(true)}
        icon={BsClipboardData}
        isLoading={isLoading}
      />

      <SectionButton
        label={LABELS.GOALS}
        secondColumn={<DollarAmount amount={goalsSum} color />}
        onClick={() => setIsGoalsExpanded(true)}
        icon={BsPiggyBank}
        isLoading={isLoading}
      />

      <BetterModal
        show={isRecoveriesExpanded}
        title={LABELS.RECOVERIES}
        onHide={() => setIsRecoveriesExpanded(false)}
        isLoading={isLoading}
        icon={BsClipboardData}
      >
        <ErrorAlert error={installmentsError || plansError} />

        {recoveries.length === 0 && (
          <NoContent>
            No recoveries affect the {timescale} of {budgetPeriods.utilization.start.format('MMM D')}.
          </NoContent>
        )}

        {recoveries.length > 0 &&
          sortBy(recoveries, (plan) => (plan.targetAmount - plan.installmentsSum) / plan.targetAmount).map((plan: PlanDocData) => (
            <PlanRow
              installmentsSum={
                recoveries
                  ? allInstallments
                      .filter((installment) => plan.__id === installment.planId)
                      .filter((installment) => dayjs(installment.date.toDate()).isBefore(budgetPeriods.utilization.end))
                      .reduce((sum, installment) => sum + installment.amount, 0)
                  : undefined
              }
              plan={plan}
              key={plan.__id}
            />
          ))}
      </BetterModal>

      <BetterModal
        show={isGoalsExpanded}
        title={LABELS.GOALS}
        onHide={() => setIsGoalsExpanded(false)}
        isLoading={isLoading}
        icon={BsPiggyBank}
      >
        <ErrorAlert error={installmentsError || plansError} />

        <NewGoalButton />

        {goalsForPeriod.length === 0 && (
          <NoContent>
            No goals affect the {timescale} of {budgetPeriods.utilization.start.format('MMM D')}.
          </NoContent>
        )}

        {goalsForPeriod.length > 0 &&
          sortBy(goalsForPeriod, (plan) => (plan.targetAmount - plan.installmentsSum) / plan.targetAmount).map((plan: PlanDocData) => (
            <PlanRow
              installmentsSum={
                goalsForPeriod
                  ? allInstallments
                      .filter((installment) => plan.__id === installment.planId)
                      .filter((installment) => dayjs(installment.date.toDate()).isBefore(budgetPeriods.utilization.end))
                      .reduce((sum, installment) => sum + installment.amount, 0)
                  : undefined
              }
              plan={plan}
              key={plan.__id}
            />
          ))}

        {completedGoals.length > 0 && completedGoals.map((plan) => <PlanRow isCompleted={true} plan={plan} key={plan.__id} />)}
      </BetterModal>
    </>
  );
}

export { usePlans };
