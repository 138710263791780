import { useAuth } from '../contexts/AuthContext';
import db from '../firebase';
import useOnSnapshot from './useOnSnapshot';
import { useState } from 'react';
import { collection, FirestoreError, query, QueryConstraint, where } from 'firebase/firestore';
import { InstallmentDocData } from '../types';
import { Dayjs } from 'dayjs';

export function useInstallments({ start, end, planId }: { start?: Dayjs; end?: Dayjs; planId?: string }) {
  const { uid } = useAuth();
  const [error, setError] = useState<FirestoreError>();

  const wheres: QueryConstraint[] = [];

  if (start) wheres.push(where('date', '>=', start.toDate()));
  if (end) wheres.push(where('date', '<', end.toDate()));
  if (planId) wheres.push(where('planId', '==', planId));

  const installmentsQuery = query(collection(db, 'users', uid, 'installments'), ...wheres);
  const { docs, isLoading } = useOnSnapshot<InstallmentDocData>(installmentsQuery, { onError: setError });

  return {
    error,
    isLoading,
    installments: docs,
  };
}
