import React from 'react';
import { Container, Col, Row, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { useAuth } from '../contexts/AuthContext';
import { Route } from '../Routes';
import UserDropdownMenu from './UserDropdownMenu';
import useUser from '../hooks/useUser';
import { useAppLayout } from '../contexts/AppLayoutContext';

const BUTTON_SIZE = 36;

export default function Header() {
  const { user: authUser, impersonating } = useAuth();
  const { user } = useUser();
  const { openSearchModal } = useAppLayout();

  return (
    <header className="mb-3">
      {impersonating && <Alert variant="warning">You are impersonating {authUser.email}.</Alert>}

      <Container fluid className="gx-0">
        <Row className="gx-0 align-items-center">
          <Col xs={3}>
            <Link to={Route.Root}>
              <img
                src="/logo.svg"
                width={100}
                height={(132 / 320) * 100}
                alt="Spend logo, which is money in an envelope"
                className="logo"
                style={{ display: 'block' }}
              />
            </Link>
          </Col>
          <Col xs={9} className="justify-content-end d-flex">
            {user && !user.onboardingStep && (
              <Button
                variant="secondary"
                className="rounded-circle p-0 border-0 me-2 d-flex align-items-center justify-content-center"
                style={{ height: BUTTON_SIZE, width: BUTTON_SIZE }}
                onClick={openSearchModal}
              >
                <BsSearch size={BUTTON_SIZE * 0.5} style={{}} />
              </Button>
            )}
            <UserDropdownMenu />
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export { BUTTON_SIZE };
