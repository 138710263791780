import React, { useState } from 'react';
import GoalModalForm from './GoalModalForm';
import { Button, ButtonProps } from 'react-bootstrap';

interface NewGoalButtonProps extends ButtonProps {}

export default function NewGoalButton({ ...rest }: NewGoalButtonProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button {...rest} onClick={() => setShowModal(true)} className="d-block w-100">
        Create a goal
      </Button>

      <GoalModalForm showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
