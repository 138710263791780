import React from 'react';
import { createPortal } from 'react-dom';
import { BarLoader } from 'react-spinners';
import useUser from '../hooks/useUser';

const HEIGHT = 2;

export default function Loader({ isLoading }) {
  const { user } = useUser();

  return createPortal(
    <div style={{ height: HEIGHT }}>
      {(user?.isRecalculatingAllBudgets || isLoading) && <BarLoader height={HEIGHT} color="var(--bs-blue)" width="100%" />}
    </div>,
    document.getElementById('loader'),
  );
}
