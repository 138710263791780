import React from 'react';
import { Badge, Spinner, Stack, Table } from 'react-bootstrap';
import DollarAmount from '../DollarAmount';
import pluralize from '../../shared/pluralize';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { useDashboard } from '../../contexts/DashboardContext';
import useBalanceSheet from '../../hooks/useBalanceSheet';
import formatRunway from '../../shared/formatRunway';
import { Timescale } from '../../shared/BudgetCalendar';
import Tip from '../Tip';
import BetterModal from '../BetterModal';
import { useAppLayout } from '../../contexts/AppLayoutContext';

const RunwayDateFormat = 'MMM D';

type RunwayModalProps = {
  show: boolean;
  onHide: () => void;
};

export function RunwayBadge({ runwayInMonths }) {
  let bg = 'success';
  let runwayLabel = pluralize(runwayInMonths, 'month');

  if (runwayInMonths > 999) runwayLabel = '1,000+ months';
  if (runwayInMonths <= 0) {
    bg = 'danger';
    runwayLabel = 'None';
  } else if (runwayInMonths < 3) bg = 'warning';

  return (
    <Badge bg={bg} text={bg === 'warning' ? 'dark' : 'light'}>
      <Stack direction="horizontal" gap={1}>
        {runwayInMonths < 3 && <BsExclamationTriangleFill />}
        {runwayLabel}
      </Stack>
    </Badge>
  );
}

export default function RunwayModal({ show, onHide }: RunwayModalProps) {
  const { budgetPeriods, timescale } = useAppLayout();
  const { runway } = useDashboard();
  const { balanceSheet } = useBalanceSheet({ createdOnOrBefore: budgetPeriods.utilization.end });

  console.log({ balanceSheet });
  let averageSpendingTimeframe: string;
  let averageSpendingDateString: string;

  // if it's a week, show the last 4 weeks, otherwise show the last 3 months
  // ex: July 1 - July 28th or April - June
  if (timescale === Timescale.Week) {
    averageSpendingTimeframe = '4 weeks';
    averageSpendingDateString = `${budgetPeriods.utilization.start
      .subtract(4, 'weeks')
      .format(RunwayDateFormat)} - ${budgetPeriods.utilization.end.subtract(1, 'week').format(RunwayDateFormat)}`;
  } else {
    averageSpendingTimeframe = '3 months';
    averageSpendingDateString = `${budgetPeriods.utilization.start.subtract(3, 'months').format('MMM')} - ${budgetPeriods.utilization.start
      .subtract(1, 'month')
      .format('MMM')}`;
  }

  return (
    <BetterModal show={show} onHide={onHide} title="Runway">
      <Tip style={{ marginBottom: '0.8rem' }}>
        Your runway is how many months your cash will last if your monthly expenses and your average spending stay the same.
      </Tip>

      <Table size="sm" borderless className="mb-0">
        <tbody>
          <tr>
            <td>
              Cash balance
              <div className="small-font text-muted">
                Checking & savings as of {budgetPeriods.utilization.start.format(RunwayDateFormat)}
              </div>
            </td>
            <td className="text-end">
              <DollarAmount amount={balanceSheet.assets} />
            </td>
          </tr>
          <tr>
            <td>
              Average spending
              <div className="small-font text-muted">
                Over the previous {averageSpendingTimeframe}, {averageSpendingDateString}
              </div>
            </td>
            <td className="text-end">
              {!runway && <Spinner size="sm" className="text-muted" />}
              {runway && <DollarAmount amount={runway.averageUtilization} per={timescale} keepSign />}
            </td>
          </tr>
          <tr>
            <td>
              Monthly expenses
              <div className="small-font text-muted">
                The total of your recurring expenses for the prior month, {budgetPeriods.recurrence.start.format(RunwayDateFormat)} -{' '}
                {budgetPeriods.recurrence.end.format(RunwayDateFormat)}
              </div>
            </td>
            <td className="text-end">
              {!runway && <Spinner size="sm" className="text-muted" />}
              {runway && <DollarAmount amount={runway.averageRecurringExpenses} per={timescale} keepSign />}
            </td>
          </tr>
          <tr>
            <td>
              Runway
              <div className="small-font text-muted">
                Your cash balance divided by the total of your average spending and monthly expenses
              </div>
            </td>
            <td className="text-end" data-testid="runway-badge">
              {!runway && <Spinner size="sm" className="text-muted" />}
              {runway && <RunwayBadge runwayInMonths={runway.runwayInMonths} />}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <hr />
            </td>
          </tr>
          <tr>
            <td>
              Liabilities
              <div className="small-font text-muted">
                Credit card & loan balances as of {budgetPeriods.utilization.start.format(RunwayDateFormat)}
              </div>
            </td>
            <td className="text-end">
              {!balanceSheet && <Spinner size="sm" className="text-muted" />}
              {balanceSheet && <DollarAmount amount={balanceSheet?.liabilities} color />}
            </td>
          </tr>
          <tr>
            <td>
              Runway with liabilities
              <div className="small-font text-muted">Your runway assuming you paid off your liabilities</div>
            </td>
            <td className="text-end" data-testid="liability-badge">
              {(!runway || !balanceSheet) && <Spinner size="sm" className="text-muted" />}
              {runway && balanceSheet && (
                <RunwayBadge
                  runwayInMonths={formatRunway(
                    (balanceSheet.assets + balanceSheet.liabilities) / (balanceSheet.assets / runway.runwayInMonths),
                  )}
                />
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </BetterModal>
  );
}
