import React from 'react';
import { Spinner } from 'react-bootstrap';
import { IconType } from 'react-icons/lib';

interface SectionButtonProps {
  label: React.ReactNode;
  secondColumn: React.ReactNode;
  onClick: () => void;
  icon: IconType;
  isLoading: boolean;
}

export default function SectionButton({ secondColumn, label, onClick, icon: Icon, isLoading }: SectionButtonProps) {
  return (
    <button
      onClick={onClick}
      className="d-flex w-100 p-3 rounded-2 align-items-center text-reset"
      style={{ background: 'var(--bs-secondary-bg)', border: 0 }}
    >
      {Icon && (
        <div className="me-3">
          <Icon size={24} className="d-block" />
        </div>
      )}
      <div className="me-auto">
        <strong>{label}</strong>
      </div>
      <div className="fw-bold">{isLoading ? <Spinner size="sm" className="text-muted" /> : secondColumn}</div>
    </button>
  );
}
