import { useState } from 'react';
import { query, collection, FirestoreError, where, documentId } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import db from '../firebase';
import useOnSnapshot from './useOnSnapshot';
import { UserDocData } from '../types';

export default function useUser() {
  const { uid } = useAuth();
  const [error, setError] = useState<FirestoreError>();

  const { docs, isLoading } = useOnSnapshot<UserDocData>(query(collection(db, 'users'), where(documentId(), '==', uid)), {
    onError: setError,
  });

  return {
    error,
    isLoading,
    user: docs[0],
  };
}
