import React, { useState } from 'react';
import { Timescale } from '../../shared/BudgetCalendar';
import DollarAmount from '../DollarAmount';
import { useDashboard } from '../../contexts/DashboardContext';
import useTransactions from '../../hooks/useTransactions';
import { TransactionRows } from '../TransactionRow';
import isAccountChecking from '../../utils/isAccountChecking';
import RecurringExpensesByCategory from '../RecurringExpensesByCategory';
import SectionButton from './SectionButton';
import { Stack } from 'react-bootstrap';
import { BsCalendar3, BsCashCoin } from 'react-icons/bs';
import BetterModal from '../BetterModal';
import { LABELS } from '../../shared/constants';
import Tip from '../Tip';
import ErrorAlert from '../ErrorAlert';
import NoContent from '../NoContent';
import TextLink from '../TextLink';
import { ExplainerModalId } from '../../layouts/AppLayout';
import { useAppLayout } from '../../contexts/AppLayoutContext';

export default function IncomeAndExpenses() {
  const { openExplainerModal, timescale, budgetPeriods } = useAppLayout();
  const { budget, includedAccounts, accountsById } = useDashboard();
  const [isIncomesExpanded, setIsIncomesExpanded] = useState(false);
  const [isExpensesExpanded, setIsExpensesExpanded] = useState(false);

  const {
    incomes: allIncomes,
    expenses,
    error,
    isLoading: isLoadingTransactions,
  } = useTransactions({
    startDate: budgetPeriods.recurrence.start,
    endDate: budgetPeriods.recurrence.end,
    accounts: includedAccounts,
    orderByDirection: 'desc',
  });

  let incomesSum = 0;
  let expensesSum = 0;

  if (budget) {
    if (timescale === Timescale.Week) {
      incomesSum = budget.weekRecurrencePeriodIncomesSum / (budget.weekRecurrencePeriodLength / 7);
      expensesSum = budget.weekRecurrencePeriodExpensesSum / (budget.weekRecurrencePeriodLength / 7);
    }

    if (timescale === Timescale.Month) {
      incomesSum = budget.monthRecurrencePeriodIncomesSum;
      expensesSum = budget.monthRecurrencePeriodExpensesSum;
    }
  }

  const incomes = allIncomes.filter((income) => isAccountChecking(accountsById[income.accountId]));
  const isLoading = !budget || isLoadingTransactions;

  return (
    <>
      <SectionButton
        label={LABELS.INCOME}
        secondColumn={<DollarAmount amount={incomesSum} color />}
        onClick={() => setIsIncomesExpanded(true)}
        icon={BsCashCoin}
        isLoading={isLoading}
      />

      <SectionButton
        label={LABELS.RECURRING_EXPENSES}
        secondColumn={<DollarAmount amount={expensesSum} color />}
        onClick={() => setIsExpensesExpanded(true)}
        icon={BsCalendar3}
        isLoading={isLoading}
      />

      <BetterModal
        show={isIncomesExpanded}
        title={LABELS.INCOME}
        onHide={() => setIsIncomesExpanded(false)}
        isLoading={isLoading}
        icon={BsCashCoin}
      >
        <ErrorAlert error={error} />

        {incomes.length === 0 && (
          <NoContent>
            No deposits between {budgetPeriods.recurrence.start.format('MMM D')} - {budgetPeriods.recurrence.end.format('D')}.
          </NoContent>
        )}

        {incomes.length > 0 && (
          <Stack gap={3}>
            <Tip>
              <strong>Select income from last month’s deposits</strong> like paychecks, rental income, and dividends. Avoid selecting
              one-off deposits, transfers, or paybacks.{' '}
              <TextLink onClick={() => openExplainerModal(ExplainerModalId.RecurringIncomes)}>Learn more</TextLink>
            </Tip>
            <TransactionRows transactions={incomes} accountsById={accountsById} toggleRecurringOnly />
          </Stack>
        )}
      </BetterModal>

      <BetterModal
        show={isExpensesExpanded}
        title={LABELS.RECURRING_EXPENSES}
        onHide={() => setIsExpensesExpanded(false)}
        isLoading={isLoading}
        icon={BsCalendar3}
      >
        <ErrorAlert error={error} />

        {expenses.length === 0 && (
          <NoContent>
            No expenses between {budgetPeriods.recurrence.start.format('MMM D')} - {budgetPeriods.recurrence.end.format('MMM D')}.
          </NoContent>
        )}

        {expenses.length > 0 && (
          <>
            <Tip>
              <strong>Select your essentials from last month’s expenses:</strong> rent/mortgage, insurance, utilities, gym memberships,
              child care, subscription services,{' '}
              <TextLink onClick={() => openExplainerModal(ExplainerModalId.Essentials)}>and more</TextLink>.
            </Tip>

            <RecurringExpensesByCategory transactions={expenses} accountsById={accountsById} />
          </>
        )}
      </BetterModal>
    </>
  );
}
