import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import DashboardGauge from './DashboardGauge';
import Loader from '../Loader';
import useBudget, { UseBudgetOptions } from '../../hooks/useBudget';
import useAccounts from '../../hooks/useAccounts';
import BudgetCalendar, { Timescale } from '../../shared/BudgetCalendar';
import ErrorAlert from '../ErrorAlert';
import DashboardTimeRangeSelector from './DashboardTimeRangeSelector';
import { recalculateBudgets } from '../../firebase';
import DashboardContext from '../../contexts/DashboardContext';
import BudgetView from '../../shared/BudgetView';
import { Runway } from '../../types';
import IncomeAndExpenses from './IncomeAndExpenses';
import RecoveriesAndGoals from './RecoveriesAndGoals';
import Spending from './Spending';
import Carryover from './Carryover';
import useUser from '../../hooks/useUser';
import { useAppLayout } from '../../contexts/AppLayoutContext';

function Dashboard() {
  const { user } = useUser();
  const { timescale, budgetPeriods } = useAppLayout();
  const [isCreatingBudget, setIsCreatingBudget] = useState(false);
  const { accountsById, error: accountsError, includedAccounts, isLoading: isLoadingAccounts } = useAccounts();

  const useBudgetParams: UseBudgetOptions = {};

  if (timescale === Timescale.Week) useBudgetParams.weekEnd = budgetPeriods.utilization.end;
  if (timescale === Timescale.Month) useBudgetParams.monthEnd = budgetPeriods.utilization.end;

  const {
    budget,
    error: budgetError,
    isLoading: isLoadingBudget,
  } = useBudget({
    ...useBudgetParams,
    onSnapshot: (snapshot) => {
      if (
        snapshot.empty &&
        !isCreatingBudget &&
        // This may be susceptible to a bug if the user has multiple empty weeks
        // in a row. We should consider a more robust solution.
        BudgetCalendar.isNowPeriod(budgetPeriods.utilization.start, timescale)
      ) {
        setIsCreatingBudget(true);
        recalculateBudgets(budgetPeriods.utilization.start.toDate()).catch();
      } else {
        setIsCreatingBudget(false);
      }
    },
  });

  let budgetView: BudgetView;
  let runway: Runway;

  if (budget) {
    if (timescale === Timescale.Month) {
      budgetView = new BudgetView(
        budget.monthAllowance,
        budget.monthRunway.averageUtilization,
        budget.monthUtilization,
        user?.isAllowanceBasedOnMovingAverage,
      );
      runway = budget.monthRunway;
    } else if (timescale === Timescale.Week) {
      budgetView = new BudgetView(
        budget.weekAllowance,
        budget.weekRunway.averageUtilization,
        budget.weekUtilization,
        user?.isAllowanceBasedOnMovingAverage,
      );
      runway = budget.weekRunway;
    }
  }

  // Keep this for debugging purposes, while we roll this out and troubleshoot issues
  console.log({
    timescale,
    budgetPeriods,
    budget,
    budgetView,
  });

  return (
    <DashboardContext.Provider
      value={{
        accountsById,
        budget,
        budgetView,
        includedAccounts,
        isCreatingBudget,
        runway,
      }}
    >
      <Loader isLoading={isLoadingAccounts || isLoadingBudget || isCreatingBudget} />

      <ErrorAlert error={accountsError} />
      <ErrorAlert error={budgetError} />

      <Stack gap={2}>
        <section>
          <DashboardTimeRangeSelector />
        </section>

        <section className="dashboard-gauge-container">
          <DashboardGauge />
        </section>

        <section>
          <Stack gap={2}>
            <Spending />
            <IncomeAndExpenses />
            <RecoveriesAndGoals />
            <Carryover />
          </Stack>
        </section>
      </Stack>
    </DashboardContext.Provider>
  );
}

export default Dashboard;
