import { collection, FirestoreError, query } from 'firebase/firestore';
import db from '../firebase';
import useOnSnapshot from '../hooks/useOnSnapshot';
import { useAuth } from '../contexts/AuthContext';
import isAccountIncluded from '../utils/isAccountIncluded';
import isAccountChecking from '../utils/isAccountChecking';
import { useState } from 'react';
import { AccountDocData } from '../types';

export default function useAccounts(onSnapshot?: () => void) {
  const { uid } = useAuth();
  const [error, setError] = useState<FirestoreError | undefined>();

  const collectionRef = collection(db, 'users', uid, 'accounts');
  const accountsQuery = query(collectionRef);

  const {
    docs: accounts,
    subscribe,
    unsubscribe,
    isLoading,
  }: {
    docs: AccountDocData[];
    subscribe: () => void;
    unsubscribe: () => void;
    isLoading: boolean;
  } = useOnSnapshot(accountsQuery, {
    onSnapshot,
    onError: setError,
  });

  const accountsById: {
    [key: string]: AccountDocData;
  } = {};

  for (const account of accounts) {
    accountsById[account.accountId] = account;
  }

  const includedAccounts = accounts.filter(isAccountIncluded);
  const includedCheckingAccounts = includedAccounts.filter(isAccountChecking);

  return {
    accounts,
    accountsById,
    collectionRef,
    error,
    includedAccounts,
    includedCheckingAccounts,
    isLoading,
    query: accountsQuery,
    subscribe,
    unsubscribe,
  };
}
