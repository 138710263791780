import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Modal, Stack } from 'react-bootstrap';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { Route } from '../Routes';
import { useAuth } from '../contexts/AuthContext';
import MarComHeader from '../components/MarComHeader';
import MarComFooter from '../components/MarComFooter';
import useScopeStyles from '../hooks/useScopeStyles';
import useLandingPageVersion from '../hooks/useLandingPageVersion';
import BetterButton from '../components/BetterButton';
import { BsApple, BsChevronRight, BsGoogle } from 'react-icons/bs';
import { CallToAction } from '../pages/HomePage';

const LOG_IN = 'Log in';
const SIGN_UP = 'Sign up';

function MarComLayout() {
  useDocumentTitle();
  useScopeStyles('marcom');
  useLandingPageVersion();

  const { signInWithGoogle, signInWithApple } = useAuth();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authModalHeader, setAuthModalHeader] = useState(SIGN_UP);

  function handleSignIn() {
    setShowAuthModal(true);
    setAuthModalHeader(LOG_IN);
  }

  function handleSignUp() {
    setShowAuthModal(true);
    setAuthModalHeader(SIGN_UP);
  }

  async function handleGoogleButtonClick() {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAppleButtonClick() {
    try {
      await signInWithApple();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <MarComHeader handleSignUp={handleSignUp} handleSignIn={handleSignIn} />

      <main>
        <Outlet context={{ handleSignUp }} />
      </main>

      <MarComFooter />

      <Modal show={showAuthModal} onHide={() => setShowAuthModal(false)}>
        <Modal.Header closeButton>{authModalHeader}</Modal.Header>
        <Modal.Body>
          <Stack gap={3} style={{ alignItems: 'center' }}>
            {authModalHeader === SIGN_UP && (
              <>
                <CallToAction />
                <hr className="w-100" />
              </>
            )}
            <div className="d-flex flex-column">
              <BetterButton beforeIcon={<BsGoogle />} afterIcon={<BsChevronRight />} onClick={handleGoogleButtonClick}>
                Continue with Google
              </BetterButton>
              <BetterButton beforeIcon={<BsApple />} afterIcon={<BsChevronRight />} onClick={handleAppleButtonClick} className="mt-3">
                Continue with Apple
              </BetterButton>
            </div>
            <hr className="w-100" />
            <div className="text-center small-font text-muted">
              By proceeding, you are agreeing to our <Link to={Route.Privacy}>Privacy</Link> and <Link to={Route.Security}>Security</Link>{' '}
              policies as well as the use of cookies.
            </div>
          </Stack>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MarComLayout;
