import React from 'react';
import { Modal, Alert, Table } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { collection, doc, getDocs, query, updateDoc, where, writeBatch } from 'firebase/firestore';
import { chunk } from 'underscore';
import db, { recalculateBudgets, deleteUserPlaidItem } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import LoadingNumber from './LoadingNumber';
import { FIRESTORE_MAX_BATCH_SIZE, SPEND_DEMO_ACCOUNT_NAME } from '../shared/constants';
import BetterButton from './BetterButton';

export default function RemovePlaidItem({ plaidItemId, institution, onSuccess, onConfirm, onCancel }) {
  const { uid } = useAuth();

  const [accountIds, setAccountIds] = useState([]);
  const [balanceSheetIds, setBalanceSheetIds] = useState([]);
  const [transactionIds, setTransactionIds] = useState([]);
  const [numAccounts, setNumAccounts] = useState(0);
  const [numBalanceSheets, setNumBalanceSheets] = useState(0);
  const [numTransactions, setNumTransactions] = useState(0);
  const [numDeletedAccounts, setNumDeletedAccounts] = useState(0);
  const [numDeletedBalanceSheets, setNumDeletedBalanceSheets] = useState(0);
  const [numDeletedTransactions, setNumDeletedTransactions] = useState(0);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [error, setError] = useState<Error>();

  /**
   * Gets the IDs and counts for all the Accounts, BalanceSheets, and Transactions associated to
   * this PlaidItem and returns true if there are none.
   * @param {*} skipLoading If true, will not toggle the loading state
   */
  const initConfirmationModal = useCallback(
    async (skipLoading = false) => {
      if (!skipLoading) setIsLoading(true);

      const { docs: accountDocs } = await getDocs(query(collection(db, 'users', uid, 'accounts'), where('plaidItemId', '==', plaidItemId)));
      const { docs: balanceSheetDocs } = await getDocs(
        query(collection(db, 'users', uid, 'balanceSheets'), where('createdForPlaidItemId', '==', plaidItemId)),
      );

      const nextAccountIds = accountDocs.map((doc) => doc.id);
      const nextBalanceSheetIds = balanceSheetDocs.map((doc) => doc.id);
      let nextTransactionIds = [];

      for (const accountId of nextAccountIds) {
        const { docs: transactionDocs } = await getDocs(
          query(collection(db, 'users', uid, 'transactions'), where('accountId', '==', accountId)),
        );

        nextTransactionIds = [...nextTransactionIds, ...transactionDocs.map((doc) => doc.id)];
      }

      setAccountIds(nextAccountIds);
      setBalanceSheetIds(nextBalanceSheetIds);
      setNumAccounts(nextAccountIds.length);
      setNumBalanceSheets(nextBalanceSheetIds.length);
      setNumTransactions(nextTransactionIds.length);
      setTransactionIds(nextTransactionIds);

      if (!skipLoading) setIsLoading(false);

      return nextAccountIds.length === 0 && nextBalanceSheetIds.length === 0 && nextTransactionIds.length === 0;
    },
    [uid, plaidItemId],
  );

  useEffect(() => {
    if (isConfirming) initConfirmationModal().catch(console.error);
  }, [isConfirming, initConfirmationModal]);

  const handleRemove = async () => {
    setError(null);
    setIsRemoving(true);

    try {
      if (institution.name === SPEND_DEMO_ACCOUNT_NAME) {
        await updateDoc(doc(db, 'users', uid), { demoData: false });
      } else {
        await deleteUserPlaidItem({ itemId: plaidItemId });
      }

      for (const ids of chunk(accountIds, FIRESTORE_MAX_BATCH_SIZE)) {
        const batch = writeBatch(db);

        for (const id of ids) {
          batch.delete(doc(db, 'users', uid, 'accounts', id));
        }

        await batch.commit();

        setNumAccounts((prev) => prev - ids.length);
        setNumDeletedAccounts((prev) => prev + ids.length);
      }

      for (const ids of chunk(balanceSheetIds, FIRESTORE_MAX_BATCH_SIZE)) {
        const batch = writeBatch(db);

        for (const id of ids) {
          batch.delete(doc(db, 'users', uid, 'balanceSheets', id));
        }

        await batch.commit();

        setNumDeletedBalanceSheets((prev) => prev + ids.length);
      }

      for (const ids of chunk(transactionIds, FIRESTORE_MAX_BATCH_SIZE)) {
        const batch = writeBatch(db);

        for (const id of ids) {
          batch.delete(doc(db, 'users', uid, 'transactions', id));
        }

        await batch.commit();

        setNumTransactions((prev) => prev - ids.length);
        setNumDeletedTransactions((prev) => prev + ids.length);
      }

      recalculateBudgets();

      const nothingLeftToDelete = await initConfirmationModal(true);

      if (nothingLeftToDelete) {
        resetState();
        if (onSuccess) onSuccess(institution);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsRemoving(false);
    }
  };

  const handleOpen = () => {
    setIsConfirming(true);
    setError(null);
    if (onConfirm) onConfirm();
  };

  const handleCancel = () => {
    resetState();
    if (onCancel) onCancel();
  };

  const resetState = () => {
    setAccountIds([]);
    setError(null);
    setIsConfirming(false);
    setIsLoading(false);
    setIsRemoving(false);
    setNumAccounts(0);
    setNumBalanceSheets(0);
    setNumDeletedAccounts(0);
    setNumDeletedBalanceSheets(0);
    setNumDeletedTransactions(0);
    setNumTransactions(0);
    setTransactionIds([]);
  };

  return (
    <>
      <div className="small-font text-center">
        <BetterButton onClick={handleOpen} variant="link" size="sm" className="text-danger">
          Remove
        </BetterButton>
      </div>

      <Modal show={isConfirming}>
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You’re about to remove Spend’s connection to <strong>{institution.name}</strong>. Doing this has no effect on your data stored
            with {institution.name} but may affect your Spend budget! Spend will no longer be able to download information from this bank
            and the following data will be deleted:
          </p>

          <Table borderless size="sm">
            <thead>
              <tr>
                <td></td>
                <th style={{ textAlign: 'right' }}>Stored</th>
                <th style={{ textAlign: 'right' }}>Deleted</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Accounts</th>
                <td style={{ textAlign: 'right' }}>
                  <LoadingNumber isLoading={isLoading} num={numAccounts} />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <LoadingNumber isLoading={false} num={numDeletedAccounts} />
                </td>
              </tr>
              <tr>
                <th>Balance sheets</th>
                <td style={{ textAlign: 'right' }}>
                  <LoadingNumber isLoading={isLoading} num={numBalanceSheets} />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <LoadingNumber isLoading={false} num={numDeletedBalanceSheets} />
                </td>
              </tr>
              <tr>
                <th>Transactions</th>
                <td style={{ textAlign: 'right' }}>
                  <LoadingNumber isLoading={isLoading} num={numTransactions} />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <LoadingNumber isLoading={false} num={numDeletedTransactions} />
                </td>
              </tr>
            </tbody>
          </Table>

          {error && <Alert variant="danger">{error.message}</Alert>}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <BetterButton variant="link" onClick={handleCancel} isLoading={isRemoving} disabled={isLoading} hideLoadingIndicators>
            Cancel
          </BetterButton>
          <BetterButton variant="danger" onClick={handleRemove} isLoading={isRemoving} loadingText="Removing" disabled={isLoading}>
            Remove
          </BetterButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}
