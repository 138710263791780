import React, { useState } from 'react';
import { useDashboard } from '../../contexts/DashboardContext';
import useTransactions from '../../hooks/useTransactions';
import { TransactionRows } from '../TransactionRow';
import usePlans from '../../hooks/usePlans';
import SectionButton from './SectionButton';
import BetterModal from '../BetterModal';
import DollarAmount from '../DollarAmount';
import { BsCreditCard } from 'react-icons/bs';
import ErrorAlert from '../ErrorAlert';
import NoContent from '../NoContent';
import { useAppLayout } from '../../contexts/AppLayoutContext';

const LABEL = 'Spending';

export default function Spending() {
  const { budgetPeriods } = useAppLayout();
  const { includedAccounts, accountsById, budgetView } = useDashboard();
  const [isExpanded, setIsExpanded] = useState(false);
  const { plansById } = usePlans();

  const {
    transactions,
    isLoading: isTransactionsLoading,
    error,
  } = useTransactions({
    startDate: budgetPeriods.utilization.start,
    endDate: budgetPeriods.utilization.end,
    accounts: includedAccounts,
  });

  const isLoading = isTransactionsLoading || !budgetView;

  return (
    <>
      <SectionButton
        label={LABEL}
        secondColumn={<DollarAmount amount={budgetView?.utilization} color />}
        onClick={() => setIsExpanded(true)}
        icon={BsCreditCard}
        isLoading={isLoading}
      />

      <BetterModal show={isExpanded} title={LABEL} onHide={() => setIsExpanded(false)} isLoading={isLoading} icon={BsCreditCard}>
        <ErrorAlert error={error} />

        {transactions.length === 0 && (
          <NoContent>
            No transactions between {budgetPeriods.utilization.start.format('MMM D')} - {budgetPeriods.utilization.end.format('MMM D')}.
          </NoContent>
        )}

        {transactions.length > 0 && (
          <TransactionRows
            transactions={transactions}
            accountsById={accountsById}
            endingBalance={budgetView?.utilization}
            plansById={plansById}
          />
        )}
      </BetterModal>
    </>
  );
}
