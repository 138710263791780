import React, { useState } from 'react';
import { Timescale } from '../../shared/BudgetCalendar';
import { useDashboard } from '../../contexts/DashboardContext';
import DollarAmount from '../DollarAmount';
import SectionButton from './SectionButton';
import { BsPlusSlashMinus } from 'react-icons/bs';
import { LABELS } from '../../shared/constants';
import BetterModal from '../BetterModal';
import { useAppLayout } from '../../contexts/AppLayoutContext';

export default function Carryover() {
  const { timescale } = useAppLayout();
  const { budget } = useDashboard();
  const [isExpanded, setIsExpanded] = useState(false);

  let carryover = 0;

  if (budget) {
    if (timescale === Timescale.Week) carryover = budget.previousWeekRemainder;
    if (timescale === Timescale.Month) carryover = budget.previousMonthRemainder;
  }

  const isLoading = !budget;

  return (
    <>
      <SectionButton
        label={LABELS.CARRYOVER}
        secondColumn={<DollarAmount amount={carryover} color />}
        onClick={() => setIsExpanded(true)}
        icon={BsPlusSlashMinus}
        isLoading={isLoading}
      />

      <BetterModal
        show={isExpanded}
        onHide={() => setIsExpanded(false)}
        title={LABELS.CARRYOVER}
        icon={BsPlusSlashMinus}
        isLoading={isLoading}
      >
        <p className="mb-0">
          Last {timescale} you {carryover > 0 ? 'overspent' : 'underspent'} by <DollarAmount amount={carryover} absolute />, which is{' '}
          {carryover > 0 ? 'subtracted from' : 'added to'} your Safe to Spend {timescale}.
        </p>
      </BetterModal>
    </>
  );
}
