import React, { useEffect, useState } from 'react';
import { Alert, ModalProps, Spinner, Table } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';
import db from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import DollarAmount from '../DollarAmount';
import { utcDay } from '../../shared/days';
import { useInstallments } from '../../hooks/useInstallments';
import dayjs from 'dayjs';
import DeletePlanButton from './DeletePlanButton';
import { ComponentsSeparatedByDots } from '../DotSeparator';
import pluralize from '../../shared/pluralize';
import { PlanDocData, TransactionDocData } from '../../types';
import BetterModal from '../BetterModal';
import { useAppLayout } from '../../contexts/AppLayoutContext';
import TextLink from '../TextLink';

interface PlanModalProps extends ModalProps {
  plan: PlanDocData;
}

export default function PlanModal({ plan, ...rest }: PlanModalProps) {
  const { uid } = useAuth();
  const { openTransactionModal } = useAppLayout();

  const [isTransactionLoading, setIsTransactionLoading] = useState(true);
  const [transaction, setTransaction] = useState<TransactionDocData>(null);
  const [show, setShow] = useState(true);

  const {
    installments,
    isLoading: isInstallmentsLoading,
    error: installmentsError,
  } = useInstallments({
    planId: plan.__id,
  });

  useEffect(() => {
    if (!plan?.transactionId) return;

    const getTransaction = async () => {
      const transactionDoc = doc(db, 'users', uid, 'transactions', plan.transactionId);
      const transaction = (await getDoc(transactionDoc)).data();
      setTransaction(transaction as TransactionDocData);
      setIsTransactionLoading(false);
    };

    getTransaction();
  }, [plan, uid]);

  const isDisbursement = plan.targetAmount < 0;
  const remaining = plan.targetAmount - plan.installmentsSum;
  const weeksRemaining = Math.ceil(remaining / plan.installmentAmount);
  const isAmortization = !!plan.transactionId;

  return (
    <BetterModal
      show={show}
      onHide={() => setShow(false)}
      title={plan.name}
      footer={<DeletePlanButton plan={plan} onDeleted={() => setShow(false)} />}
      {...rest}
    >
      <Alert variant="danger" show={!!installmentsError}>
        {installmentsError?.message}
      </Alert>

      {isAmortization ? (
        <p>
          On {dayjs(plan.createdAt.toDate()).format('MMMM D, YYYY')}, you recovered a <DollarAmount amount={plan.targetAmount} absolute />{' '}
          {isDisbursement ? 'deposit' : 'expense'} into weekly installments of <DollarAmount amount={plan.installmentAmount} absolute />,
          which are {isDisbursement ? 'added to' : 'subtracted from'} your allowance.
        </p>
      ) : (
        <p>
          On {dayjs(plan.createdAt.toDate()).format('MMMM D, YYYY')}, you created a goal to{' '}
          {plan.isPerpetual ? (
            <>
              save <DollarAmount amount={plan.installmentAmount} absolute /> each week.
            </>
          ) : (
            <>
              save <DollarAmount amount={plan.targetAmount} absolute /> by curtailing your weekly spending limit by{' '}
              <DollarAmount amount={plan.installmentAmount} absolute />.
            </>
          )}
        </p>
      )}

      <Table className="mb-0" borderless>
        <tbody>
          {plan.transactionId && (
            <tr>
              <th>Transaction</th>
              <td>
                {isTransactionLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <>
                    {transaction ? (
                      <>
                        <div>
                          <TextLink onClick={() => openTransactionModal(transaction)}>{transaction.name}</TextLink>
                        </div>
                        <div className="small-font text-muted">
                          <ComponentsSeparatedByDots
                            components={[
                              <DollarAmount amount={transaction.amount} color />,
                              <>{utcDay(transaction.date.toDate()).format('MMMM D, YYYY')}</>,
                            ]}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="text-danger">Not found</div>
                    )}
                  </>
                )}
              </td>
            </tr>
          )}
          <tr>
            <th>Installment amount</th>
            <td>
              <div>
                <DollarAmount amount={Math.abs(plan.installmentAmount)} absolute />{' '}
                <div className="small-font text-muted">{isDisbursement ? 'Added to' : 'Deducted from'} your spending limit each week</div>
              </div>
            </td>
          </tr>
          <tr>
            <th>{isAmortization ? 'Recovered' : 'Saved'}</th>
            <td>
              <DollarAmount amount={plan.installmentsSum} absolute />
              {!plan.isPerpetual && (
                <>
                  {' '}
                  of <DollarAmount amount={plan.targetAmount} absolute />
                  <div className="small-font text-muted">
                    <DollarAmount amount={remaining} absolute /> remaining ({pluralize(weeksRemaining, 'more week')})
                  </div>
                </>
              )}
            </td>
          </tr>
          <tr>
            <th>Installments</th>
            <td>
              {isInstallmentsLoading ? (
                <Spinner size="sm" />
              ) : (
                <>
                  {installments.length > 0 ? (
                    <ol className="small-font text-muted mb-0 mt-1 ">
                      {installments.map((installment) => (
                        <li key={installment.__id}>
                          <div className="d-flex justify-content-between">
                            <span>Week of {utcDay(installment.date.toDate()).format('MMMM D')}</span>
                            <DollarAmount amount={installment.amount} absolute />
                          </div>
                        </li>
                      ))}
                    </ol>
                  ) : (
                    <>
                      None<div className="small-font text-muted">Will start next week</div>
                    </>
                  )}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </BetterModal>
  );
}
