import { createContext, useContext } from 'react';
import { PlaidItemForUpdateDocData, PlanDocData, TransactionDocData } from '../types';
import { BudgetPeriods, Timescale } from '../shared/BudgetCalendar';
import { FirestoreError } from 'firebase/firestore';

interface AppLayoutContextType {
  budgetPeriods?: BudgetPeriods;
  isDarkMode: boolean;
  isLoadingPlaidItemsForUpdate: boolean;
  openExplainerModal: (id: string) => void;
  openPlanModal?: (plan: PlanDocData) => void;
  openSearchModal?: () => void;
  openTransactionModal?: (transaction: TransactionDocData) => void;
  plaidItemsForUpdate: PlaidItemForUpdateDocData[];
  plaidItemsForUpdateOnSnapshotError: FirestoreError;
  plaidItemsForUpdateSnapshotSubscribe: () => void;
  plaidItemsForUpdateSnapshotUnsubscribe: () => void;
  setIsDarkMode: (isDarkMode: boolean) => void;
  setToast: (toast: string) => void;
  timescale?: Timescale;
  toast: string;
}

const AppLayoutContext = createContext<AppLayoutContextType>(null);

function useAppLayout() {
  return useContext(AppLayoutContext);
}

export default AppLayoutContext;
export { useAppLayout, AppLayoutContextType };
