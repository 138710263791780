import React, { FormEvent, MouseEvent, useEffect, useState } from 'react';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import db, { connectInstitution, generateDemoData, getLinkTokens, recalculateBudgets, reconnectInstitution } from '../firebase';
import PlaidLinkButton from './PlaidLinkButton';
import { groupBy, sortBy } from 'underscore';
import { Accordion, Alert, Form, Modal, Spinner, Stack } from 'react-bootstrap';
import { BsFillExclamationCircleFill, BsLockFill, BsPlusCircle } from 'react-icons/bs';
import pluralize from '../shared/pluralize';
import Loader from './Loader';
import { ComponentsSeparatedByDots } from './DotSeparator';
import RemovePlaidItem from './RemovePlaidItem';
import { Metric, trackMetric } from '../utils/metrics';
import useAccounts from '../hooks/useAccounts';
import ErrorAlert from './ErrorAlert';
import { useAppLayout } from '../contexts/AppLayoutContext';
import DollarAmount from './DollarAmount';
import BetterAccordionItem from './BetterAccordionItem';
import { PlaidInstitution, PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import { LinkTokenResponseData } from '../types';
import BetterButton from './BetterButton';
import { useAuth } from '../contexts/AuthContext';

export default function Accounts() {
  const { uid } = useAuth();

  const {
    plaidItemsForUpdate,
    plaidItemsForUpdateSnapshotSubscribe,
    plaidItemsForUpdateSnapshotUnsubscribe,
    isLoadingPlaidItemsForUpdate,
    plaidItemsForUpdateOnSnapshotError,
    setToast,
  } = useAppLayout();

  const [linkTokens, setLinkTokens] = useState<LinkTokenResponseData>();
  const [isLoadingLinkTokens, setIsLoadingLinkTokens] = useState(false);
  const [isLoadingReconnectInstitution, setIsLoadingReconnectInstitution] = useState(false);
  const [linkTokensError, setLinkTokensError] = useState();
  const [connectInstitutionError, setConnectInstitutionError] = useState();
  const [reconnectInstitutionError, setReconnectInstitutionError] = useState();
  const [removedInstitution, setRemovedInstitution] = useState<PlaidInstitution>();
  const [newlyAddedAccounts, setNewlyAddedAccounts] = useState([]);
  const [isGeneratingDemoData, setIsGeneratingDemoData] = useState(false);
  const [demoDataError, setDemoDataError] = useState(null);
  const [isReportingIssue, setIsReportingIssue] = useState(false);
  const [issueInstitutionName, setIssueInstitutionName] = useState('');
  const [issueDescription, setIssueDescription] = useState('');

  const {
    accounts: accountsFromDb,
    subscribe: accountsSubscribe,
    unsubscribe: accountsUnsubscribe,
    isLoading: isLoadingAccounts,
    collectionRef: accountsCollectionRef,
    error: accountsError,
  } = useAccounts(() => {
    // Clear out the temporary list of accounts used to optimistically
    // update the UI since the back-end has them now.
    setNewlyAddedAccounts([]);
  });

  function unsubscribe() {
    accountsUnsubscribe();
    plaidItemsForUpdateSnapshotUnsubscribe();
  }

  function subscribe() {
    accountsSubscribe();
    plaidItemsForUpdateSnapshotSubscribe();
  }

  useEffect(() => {
    const loadLinkTokens = async () => {
      try {
        setIsLoadingLinkTokens(true);
        setLinkTokens(await getLinkTokens());
      } catch (error) {
        setLinkTokensError(error);
      } finally {
        setIsLoadingLinkTokens(false);
      }
    };

    loadLinkTokens();
  }, []);

  const handleLinkTokenSuccess = async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
    trackMetric(Metric.ACCOUNT_CONNECTED, {
      numAccounts: accountsFromDb.length,
    });

    // Optimistically update the UI, while the back-end works on downloading
    // the accounts and until onSnapshot fires next.
    setNewlyAddedAccounts(
      metadata.accounts.map(({ id, name, mask }) => ({
        isNewlyAdded: true,
        accountId: id,
        name,
        mask,
        isExcluded: true,
        institution: {
          institutionId: metadata.institution.institution_id,
          name: metadata.institution.name,
        },
      })),
    );

    try {
      await connectInstitution({ publicToken });
    } catch (error) {
      setConnectInstitutionError(error);
      setNewlyAddedAccounts([]);
    }
  };

  const handleLinkTokenForUpdateSuccess = async (plaidItemId) => {
    trackMetric(Metric.ACCOUNT_RECONNECTED);

    try {
      setIsLoadingReconnectInstitution(true);
      await reconnectInstitution({ plaidItemId });
    } catch (error) {
      setReconnectInstitutionError(error);
    } finally {
      setIsLoadingReconnectInstitution(false);
    }
  };

  const handleExcludedToggle = async (accountId, checked) => {
    trackMetric(checked ? Metric.ACCOUNT_INCLUDED : Metric.ACCOUNT_EXCLUDED);
    const accountDocRef = doc(accountsCollectionRef, accountId);
    await updateDoc(accountDocRef, { isExcluded: !checked });
    recalculateBudgets();
  };

  // If we don't unsubscribe, the RemovePlaidItem modal will disappear
  // as a result of deletion. Once the modal is closed, we can then re-subscribe,
  // which will remove the account (and the modal) from the DOM.
  const handleRemoveSuccess = (institution: PlaidInstitution) => {
    subscribe();
    setRemovedInstitution(institution);
    trackMetric(Metric.INSTITUTION_DELETED, institution);
  };

  async function handleCreateDemoData() {
    const userDocRef = doc(db, 'users', uid);
    setIsGeneratingDemoData(true);

    try {
      trackMetric(Metric.DEMO_DATA_GENERATION_STARTED);
      await generateDemoData();
      await updateDoc(userDocRef, { updatedAt: serverTimestamp(), demoData: true });
    } catch (error) {
      setDemoDataError(error);
    } finally {
      trackMetric(Metric.DEMO_DATA_GENERATION_FINISHED);
      setIsGeneratingDemoData(false);
    }
  }

  function handleReportAnIssueClick(event: MouseEvent) {
    event.preventDefault();
    setIsReportingIssue(true);
  }

  function handleReportIssueSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (issueInstitutionName.trim().length === 0) return;

    trackMetric(Metric.REPORT_ISSUE_FINISHED, {
      issue_type: 'institution connection',
      institution: issueInstitutionName,
      description: issueDescription,
    });

    setIssueInstitutionName('');
    setIssueDescription('');
    setIsReportingIssue(false);
    setToast('Thank you for reporting the problem. We will look into it.');
  }

  const accountsByPlaidItemId = groupBy([...accountsFromDb, ...newlyAddedAccounts], (account) => account.plaidItemId);

  const plaidItemIds = Object.keys(accountsByPlaidItemId);

  return (
    <>
      <Loader
        isLoading={
          isLoadingLinkTokens ||
          isLoadingAccounts ||
          isLoadingPlaidItemsForUpdate ||
          isLoadingReconnectInstitution ||
          newlyAddedAccounts.length > 0
        }
      />

      <ErrorAlert error={accountsError} />
      <ErrorAlert error={connectInstitutionError} />
      <ErrorAlert error={linkTokensError} />
      <ErrorAlert error={plaidItemsForUpdateOnSnapshotError} />
      <ErrorAlert error={reconnectInstitutionError} />

      <Stack gap={4}>
        <section>
          <Stack gap={2}>
            <div>
              <PlaidLinkButton
                variant={accountsFromDb.length > 0 ? 'outline-primary' : 'primary'}
                linkToken={linkTokens?.linkTokenForNewPlaidItem}
                onSuccess={handleLinkTokenSuccess}
                beforeIcon={<BsLockFill />}
              >
                Securely connect an account
              </PlaidLinkButton>
            </div>

            <div className="small-font text-muted">
              Spend makes a read-only connection to your financial institution and cannot access your money. Delete your data at any time.
            </div>
          </Stack>
        </section>

        {removedInstitution && (
          <Alert className="small-font" variant="success" onClose={() => setRemovedInstitution(null)} dismissible>
            Spend no longer has access to <strong>{removedInstitution.name}</strong> and all associated data has been removed as you
            requested.
          </Alert>
        )}

        {plaidItemIds.length > 0 && (
          <Accordion alwaysOpen>
            {plaidItemIds
              .sort((a, b) => {
                var institutionNameA = accountsByPlaidItemId[a][0].institution.name.toUpperCase();
                var institutionNameB = accountsByPlaidItemId[b][0].institution.name.toUpperCase();
                return institutionNameA < institutionNameB ? -1 : institutionNameA > institutionNameB ? 1 : 0;
              })
              .map((plaidItemId) => {
                const plaidItemForUpdate = plaidItemsForUpdate.filter(({ itemId }) => itemId === plaidItemId)[0];

                const needsUpdate = plaidItemForUpdate && linkTokens?.linkTokensForUpdateByPlaidItemId;
                const accounts = accountsByPlaidItemId[plaidItemId];
                const includedAccounts = accounts.filter((account) => !account.isExcluded);
                const numAccountsAreLoading = accounts.filter((account) => account.isNewlyAdded).length;
                const institution = accounts[0].institution;
                const accountsWithBalances = includedAccounts.filter((account) => account.currentBalance !== undefined);
                const isLoading = numAccountsAreLoading > 0;

                return (
                  <BetterAccordionItem
                    key={plaidItemId}
                    eventKey={plaidItemId}
                    data-plaid-item-id={plaidItemId}
                    header={institution.name}
                    icon={needsUpdate && <BsFillExclamationCircleFill color="var(--bs-danger)" />}
                    isLoading={isLoading}
                    secondLine={
                      needsUpdate
                        ? 'We are having trouble connecting to your bank'
                        : numAccountsAreLoading > 0
                        ? `Loading ${pluralize(numAccountsAreLoading, 'account')}…`
                        : `${pluralize(includedAccounts.length, 'account')} included in your budget`
                    }
                    secondColumn={
                      needsUpdate ? (
                        <PlaidLinkButton
                          linkToken={linkTokens.linkTokensForUpdateByPlaidItemId[plaidItemForUpdate.itemId]}
                          onSuccess={() => handleLinkTokenForUpdateSuccess(plaidItemForUpdate.itemId)}
                          variant="danger"
                          size="sm"
                        >
                          Reconnect
                        </PlaidLinkButton>
                      ) : isLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        accountsWithBalances.length > 0 && (
                          <DollarAmount
                            amount={accountsWithBalances.reduce((balance, account) => {
                              balance += account.currentBalance;
                              return balance;
                            }, 0)}
                            color
                          />
                        )
                      )
                    }
                  >
                    {sortBy(accounts, 'name').map(({ accountId, isExcluded, name, mask, isNewlyAdded, currentBalance }) => (
                      <div
                        key={accountId}
                        className="account"
                        style={{
                          marginBottom: '.6em',
                          paddingBottom: '.6em',
                          borderBottom: '1px solid var(--bs-border-color)',
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: 1 }}>{name}</div>
                          <Form.Check
                            key={accountId}
                            type="switch"
                            checked={!isExcluded}
                            disabled={isNewlyAdded}
                            onChange={isNewlyAdded ? null : ({ target: { checked } }) => handleExcludedToggle(accountId, checked)}
                            id={`account-${accountId}-is-excluded-switch`}
                            style={{ margin: 0 }}
                          />
                        </div>
                        <div className="small-font text-muted">
                          <ComponentsSeparatedByDots
                            components={[
                              mask && `x${mask}`,
                              currentBalance !== undefined && <DollarAmount amount={currentBalance} />,
                              isExcluded && 'Excluded from your budget',
                            ]}
                          />
                        </div>
                      </div>
                    ))}
                    <RemovePlaidItem
                      institution={institution}
                      onConfirm={unsubscribe}
                      onSuccess={handleRemoveSuccess}
                      onCancel={subscribe}
                      plaidItemId={plaidItemId}
                    />
                  </BetterAccordionItem>
                );
              })}
          </Accordion>
        )}
      </Stack>

      {accountsFromDb.length === 0 && newlyAddedAccounts.length === 0 && (
        <>
          <hr />

          <Stack>
            <ErrorAlert error={demoDataError} onClose={() => setDemoDataError(null)} />

            <p className="small-font text-muted">
              <strong>Not ready?</strong> Try Spend with a demo account and connect a personal account later.
            </p>

            <div>
              <BetterButton
                variant="outline-primary"
                size="sm"
                onClick={handleCreateDemoData}
                isLoading={isGeneratingDemoData || !linkTokens?.linkTokenForNewPlaidItem}
                beforeIcon={<BsPlusCircle />}
              >
                Add a demo account
              </BetterButton>
            </div>
          </Stack>
        </>
      )}

      <hr />

      <p className="small-font text-muted">
        <strong>Having trouble?</strong>{' '}
        <BetterButton variant="link" metric={Metric.REPORT_ISSUE_STARTED} onClick={handleReportAnIssueClick} className="btn-as-text">
          Report a problem
        </BetterButton>{' '}
        connecting your bank.
      </p>

      <Modal show={isReportingIssue} onHide={() => setIsReportingIssue(false)}>
        <Form onSubmit={handleReportIssueSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Report a problem</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="issueInstitutionName" className="mb-0">
                <strong>Institution</strong> (required)
              </Form.Label>
              <Form.Text className="d-block mb-2">Which financial institution did you try to connect?</Form.Text>
              <Form.Control
                id="issueInstitutionName"
                type="text"
                name="name"
                value={issueInstitutionName}
                onChange={(event) => setIssueInstitutionName(event.target.value)}
                required
                placeholder='e.g. "Bank of America" or "Chase"'
                autoComplete="off"
              />
            </Form.Group>

            <Form.Control as="textarea" placeholder="Optional: describe the problem you experienced" className="small-font" />
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <BetterButton variant="link" metric={Metric.REPORT_ISSUE_CANCELED} onClick={() => setIsReportingIssue(false)}>
              Cancel
            </BetterButton>

            <BetterButton variant="primary" type="submit">
              Submit
            </BetterButton>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
