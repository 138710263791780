import React from 'react';
import { Link } from 'react-router-dom';

interface TextLinkProps {
  href?: string;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

/** A link that is useful in text. It prevents the default behavior and will match the color of the parent element. */
export default function TextLink({ href = '#', children, onClick }: TextLinkProps) {
  return (
    <Link
      to={href}
      onClick={(e) => {
        if (href === '#') e.preventDefault();
        onClick?.(e);
      }}
      className="text-reset text-link"
    >
      {children}
    </Link>
  );
}
