import React from 'react';
import { Table } from 'react-bootstrap';
import { BsCalendar3, BsCashCoin, BsDash, BsEnvelopeOpenHeart } from 'react-icons/bs';
import DollarAmount from '../DollarAmount';
import useBudget from '../../hooks/useBudget';
import { Timescale } from '../../shared/BudgetCalendar';
import { LABELS } from '../../shared/constants';

export default function AllowanceTable() {
  const { budget } = useBudget();

  return (
    <Table size="sm" borderless style={{ marginBottom: 0 }}>
      <tbody>
        <tr>
          <td>
            <span className="text-muted" style={{ marginRight: 8 }}>
              <BsCashCoin />
            </span>
            <strong>{LABELS.INCOME}</strong>
          </td>
          <td style={{ textAlign: 'right' }} colSpan={2}>
            {budget && <DollarAmount amount={budget.weekRecurrencePeriodIncomesSum} />}
          </td>
        </tr>
        <tr>
          <td>
            <span className="text-muted" style={{ marginRight: 8, top: -1, position: 'relative' }}>
              <BsCalendar3 />
            </span>
            <strong>{LABELS.RECURRING_EXPENSES}</strong>
          </td>
          <td>
            <BsDash />
          </td>
          <td style={{ textAlign: 'right' }}>{budget && <DollarAmount amount={budget.weekRecurrencePeriodExpensesSum} keepSign />}</td>
        </tr>
        <tr>
          <td>
            <span className="text-muted" style={{ marginRight: 8, top: -3, position: 'relative' }}>
              <BsEnvelopeOpenHeart />
            </span>
            <strong>Allowance</strong>
          </td>
          <td style={{ borderTop: '1px solid var(--bs-border-color)' }} className="text-end" colSpan={2}>
            {budget && (
              <DollarAmount amount={budget.weekRecurrencePeriodIncomesSum + budget.weekRecurrencePeriodExpensesSum} per={Timescale.Month} />
            )}
          </td>
        </tr>
        <tr>
          <td></td>
          <td className="text-end" colSpan={2}>
            {budget && (
              <DollarAmount
                amount={
                  (budget.weekRecurrencePeriodIncomesSum + budget.weekRecurrencePeriodExpensesSum) / (budget.weekRecurrencePeriodLength / 7)
                }
                per={Timescale.Week}
              />
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
