import React, { useCallback, useState } from 'react';
import { removePlan } from '../../utils/plan';
import { useAuth } from '../../contexts/AuthContext';
import { Stack } from 'react-bootstrap';
import { PlanDocData } from '../../types';
import { BsCashCoin } from 'react-icons/bs';
import DollarAmount from '../DollarAmount';
import { utcDay } from '../../shared/days';
import ConfirmButton from '../ConfirmButton';
import { useAppLayout } from '../../contexts/AppLayoutContext';

interface DeletePlanButtonProps {
  plan: PlanDocData;
  onDeleted: () => void;
}

export default function DeletePlanButton({ plan, onDeleted }: DeletePlanButtonProps) {
  const { uid } = useAuth();
  const { setToast } = useAppLayout();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = useCallback(async () => {
    try {
      setIsDeleting(true);
      await removePlan(uid, plan);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
      onDeleted();
      setToast(plan.transactionId ? 'Recovery deleted.' : 'Goal closed & cashed-in.');
    }
  }, [uid, plan, setIsDeleting, onDeleted, setToast]);

  return (
    <div>
      <Stack gap={3}>
        {plan.transactionId ? (
          <div className="small-font text-muted">
            Deleting this recovery will reduce this week’s Safe to Spend by{' '}
            <DollarAmount keepSign amount={plan.targetAmount - plan.installmentsSum} />.
          </div>
        ) : (
          <div className="small-font text-muted">
            Cash-in the <DollarAmount amount={plan.installmentsSum} keepSign /> you’ve saved since{' '}
            {utcDay(plan.start.toDate()).format('MMMM DD, YYYY')}. This amount will be applied to your current Safe to Spend. This action
            cannot be undone.
          </div>
        )}
        <Stack direction="horizontal" gap={2}>
          {plan.transactionId ? (
            <ConfirmButton variant="danger" isLoading={isDeleting} onConfirm={handleDelete}>
              Delete
            </ConfirmButton>
          ) : (
            <ConfirmButton variant="success" isLoading={isDeleting} beforeIcon={<BsCashCoin />} onConfirm={handleDelete}>
              Close & cash-in
            </ConfirmButton>
          )}
        </Stack>
      </Stack>
    </div>
  );
}
