import React, { useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import BigSpinner from './BigSpinner';
import { IconType } from 'react-icons/lib';

interface BetterModalProps extends ModalProps {
  /** Content for the `<Modal.Body>` */
  children?: React.ReactNode;
  /** The class name of the react-icons icon (`BsArrowRepeat` not `<BsArrowRepeat />`) */
  icon?: IconType;
  /** Display a `<BigSpinner />` as content loads. */
  isLoading?: boolean;
  /** Title for the `<Modal.Title>` */
  title: string;
  footer?: React.ReactNode;
}

/**
 * A wrapper for `<Modal />` from Bootstrap that is a bit less verbose to use
 * and comes with the boilerplate for a title, icon, and loading spinner.
 */
export default function BetterModal({ isLoading = false, title, children, icon: Icon, footer, ...rest }: BetterModalProps) {
  const [show, setShow] = useState(true);

  return (
    <Modal show={show} onHide={() => setShow(false)} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center" data-testid="modal-title">
          {Icon && (
            <div className="me-3">
              <Icon className="d-block" />
            </div>
          )}
          <div className="fw-bold">{title}</div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading && <BigSpinner className="my-4" />}
        {!isLoading && show && children}
      </Modal.Body>

      {!!footer && show && <Modal.Footer className="justify-content-between">{footer}</Modal.Footer>}
    </Modal>
  );
}
