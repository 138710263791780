import { createContext, useContext } from 'react';
import BudgetView from '../shared/BudgetView';
import { AccountDocData, BudgetDocData } from '../types';

interface DashboardContextType {
  accountsById?: any;
  budget?: BudgetDocData;
  budgetView?: BudgetView;
  includedAccounts?: AccountDocData[];
  isCreatingBudget?: boolean;
  runway?: any;
}

const DashboardContext = createContext<DashboardContextType>({});

function useDashboard() {
  return useContext(DashboardContext);
}

export default DashboardContext;
export { useDashboard };
