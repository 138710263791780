import { createContext, useContext } from 'react';
import { User } from 'firebase/auth';

interface AuthContextType {
  uid?: string;
  user: User | null;
  signInWithApple: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  impersonating: string | null;
}

const AuthContext = createContext<AuthContextType>(null);

function useAuth() {
  return useContext(AuthContext);
}

export default AuthContext;
export { useAuth, AuthContextType };
