import React from 'react';
import BigSpinner from '../BigSpinner';
import { TransactionRows } from '../TransactionRow';
import AllowanceEquationWithIcons from './AllowanceEquationWithIcons';
import Tip from '../Tip';
import { ExplainerModalId } from '../../layouts/AppLayout';
import BetterButton from '../BetterButton';
import { useAppLayout } from '../../contexts/AppLayoutContext';

export default function IncomeStep({ accountsById, endDate, incomes, startDate }) {
  const { openExplainerModal } = useAppLayout();

  if (incomes.length === 0) return <BigSpinner label="Loading income for the last month" />;

  return (
    <>
      <section>
        <AllowanceEquationWithIcons phase={0} />
      </section>

      <section>
        <p>
          <strong>
            Identify recurring income between {startDate.format('MMMM D')} and {endDate.format('MMMM D')}.
          </strong>{' '}
          Examples include paychecks, rental income, and dividends. Avoid selecting one-off deposits, transfers, or paybacks.
        </p>

        <Tip>
          Your budget is based on the most recent month’s worth of income.{' '}
          <BetterButton className="btn-as-text" variant="link" onClick={() => openExplainerModal(ExplainerModalId.RecurringIncomes)}>
            Learn more
          </BetterButton>
        </Tip>
      </section>

      <div>
        <TransactionRows transactions={incomes} accountsById={accountsById} toggleRecurringOnly />
      </div>
    </>
  );
}
