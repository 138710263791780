import React from 'react';
import DollarAmount from '../DollarAmount';
import { ProgressBar } from 'react-bootstrap';
import { PlanDocData } from '../../types';
import pluralize from '../../shared/pluralize';
import TextLink from '../TextLink';
import { useAppLayout } from '../../contexts/AppLayoutContext';

interface PlanRowProps {
  plan: PlanDocData;
  installmentsSum?: number;
  isCompleted?: boolean;
}

export default function PlanRow({ plan, installmentsSum, isCompleted = false }: PlanRowProps) {
  const { openPlanModal } = useAppLayout();

  return (
    <div data-testid={`plan-${plan.__id}`} data-plan-id={plan.__id} className="list-item-with-border py-3">
      <div className="d-flex justify-content-between" style={{ gap: 8 }}>
        <div className="flex-grow-1">
          <TextLink onClick={() => openPlanModal(plan)}>{plan.name}</TextLink>
          {isCompleted && <span className="badge bg-success ms-2">Completed</span>}
        </div>
        <div>
          <DollarAmount amount={installmentsSum !== undefined ? installmentsSum : plan.installmentsSum} absolute />
          {!plan.isPerpetual && !isCompleted && (
            <>
              {' '}
              <span className="text-muted small-font">of</span> <DollarAmount amount={plan.targetAmount} absolute />
            </>
          )}
        </div>
      </div>
      <div className="small-font text-muted mt-1">
        {plan.installmentsSum !== plan.targetAmount && (
          <>
            <DollarAmount absolute amount={plan.installmentAmount} color /> set aside each week{' '}
            {!plan.isPerpetual && (
              <>for {pluralize(Math.ceil((plan.targetAmount - plan.installmentsSum) / plan.installmentAmount), 'more week')}</>
            )}
          </>
        )}
      </div>
      {!plan.isPerpetual && !isCompleted && (
        <div className="mt-2">
          <ProgressBar
            striped
            variant="success"
            now={((installmentsSum !== undefined ? installmentsSum : plan.installmentsSum) / plan.targetAmount) * 100}
            style={{ height: 'calc(var(--bs-progress-height) / 2)' }}
          />
        </div>
      )}
    </div>
  );
}
