/**
 * Truncate a string to a certain length and adds the suffix if necessary,
 * keeping the result within the length constraint.
 * @param s String to truncate
 * @param length Length to limit the string to
 * @param suffix What to put to the end if truncation is necessary
 */
function truncate(s: string, length: number, suffix: string = '…') {
  let truncated = s.trim();
  if (truncated.length <= length || truncated.length <= suffix.length) return truncated;
  return truncated.slice(0, length - suffix.length).trimEnd() + suffix;
}

export default truncate;
