import { useState } from 'react';
import { limit, orderBy, query, collection, where, QueryConstraint, FirestoreError, QuerySnapshot, DocumentData } from 'firebase/firestore';
import { Dayjs } from 'dayjs';
import { useAuth } from '../contexts/AuthContext';
import db from '../firebase';
import useOnSnapshot from './useOnSnapshot';
import { BudgetDocData } from '../types';

type UseBudgetOptions = {
  monthEnd?: Dayjs;
  onSnapshot?: (callback: QuerySnapshot<DocumentData>) => void;
  weekEnd?: Dayjs;
};

export default function useBudget({ monthEnd, weekEnd, onSnapshot }: UseBudgetOptions = {}) {
  const { uid } = useAuth();
  const [error, setError] = useState<FirestoreError>();

  const queryConstraints: QueryConstraint[] = [orderBy('weekEnd', 'desc'), orderBy('createdAt', 'desc'), limit(1)];

  if (weekEnd) queryConstraints.push(where('weekEnd', '==', weekEnd.toDate()));

  if (monthEnd) queryConstraints.push(where('monthEnd', '==', monthEnd.toDate()));

  const { docs, isLoading } = useOnSnapshot<BudgetDocData>(query(collection(db, 'users', uid, 'budgets'), ...queryConstraints), {
    queryKey: JSON.stringify({ monthEnd, weekEnd }),
    onError: setError,
    onSnapshot,
  });

  return {
    error,
    isLoading,
    budget: docs[0],
  };
}

export { UseBudgetOptions };
