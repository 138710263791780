import posthog, { CaptureOptions, Properties } from 'posthog-js';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';
import { useEffect } from 'react';
import { User } from 'firebase/auth';

const BATCH_DELAY_AMOUNT = 300;

if (process.env.NODE_ENV === 'production') {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: 'https://app.posthog.com',
    autocapture: false,
  });
}

/**
 * @param{firebase.User} user
 */
const setMetricUser = (user: User) => {
  if (process.env.NODE_ENV !== 'production') return;
  posthog.identify(user.uid, {
    name: user.displayName, // also sets the display name in the PostHog UI
    email: user.email,
    timezone: new Date().getTimezoneOffset(),
  });
};

const unsetMetricUser = () => {
  if (process.env.NODE_ENV !== 'production') return;
  posthog.reset();
};

const Metric = Object.freeze({
  ABOUT_US_PAGE_VISITED: 'about_us_page_visited',
  ACCOUNTS_PAGE_VISITED: 'accounts_page_visited',
  ACCOUNT_CONNECTED: 'account_connected',
  ACCOUNT_EXCLUDED: 'account_excluded',
  ACCOUNT_INCLUDED: 'account_included',
  ACCOUNT_RECONNECTED: 'account_reconnected',
  BUDGET_PAGE_VISITED: 'budget_page_visited',
  DASHBOARD_PAGE_VISITED: 'dashboard_page_visited',
  DEMO_DATA_GENERATION_FINISHED: 'demo_data_generation_finished',
  DEMO_DATA_GENERATION_STARTED: 'demo_data_generation_started',
  DEMO_VIDEO_MODAL_OPENED: 'demo_video_modal_opened',
  ERROR: 'error',
  EXPLAINER_MODAL_OPENED: 'explainer_modal_opened',
  EXPLAINER_MODAL_CLOSED: 'explainer_modal_closed',
  HELP_PAGE_VISITED: 'help_page_visited',
  HOME_PAGE_VISITED: 'home_page_visited',
  INSTITUTION_DELETED: 'institution_deleted',
  LOG_IN: 'log_in',
  ONBOARDING_COMPLETED: 'onboarding_completed',
  ONBOARDING_EXPENSES_WARNING_SHOWN: 'onboarding_expenses_warning_shown',
  ONBOARDING_INCOMES_WARNING_SHOWN: 'onboarding_incomes_warning_shown',
  ONBOARDING_RECURRING_INCOMES_WARNING_SHOWN: 'onboarding_recurring_incomes_warning_shown',
  ONBOARDING_STEP_REACHED: 'onboarding_step_reached',
  PLAID_ITEM_DELETED: 'plaid_item_deleted',
  PLAID_LINK_BUTTON_CLICKED: 'plaid_link_button_clicked',
  PLAID_LINK_BUTTON_READY: 'plaid_link_button_ready',
  PLAID_LINK_BUTTON_RENDERED: 'plaid_link_button_rendered',
  PLAID_LINK_EVENT: 'plaid_link_event',
  PLAID_LINK_LOAD: 'plaid_link_load',
  PLAID_LINK_SUCCESS: 'plaid_link_success',
  PRIVACY_PAGE_VISITED: 'privacy_page_visited',
  REPORT_ISSUE_STARTED: 'report_issue_started',
  REPORT_ISSUE_FINISHED: 'report_issue_finished',
  REPORT_ISSUE_CANCELED: 'report_issue_canceled',
  SECURITY_PAGE_VISITED: 'security_page_visited',
  SIGN_UP: 'sign_up',
  TRANSACTIONS_PAGE_VISITED: 'transactions_page_visited',
  TRANSACTION_MARKED_IGNORED: 'transaction_marked_ignored',
  TRANSACTION_MARKED_RECURRING: 'transaction_marked_recurring',
  TRANSACTION_MODAL_SEEN: 'transaction_modal_seen',
  TUTORIAL_VIDEO_LOADED: 'tutorial_video_loaded',
  TUTORIAL_VIDEO_PLAYED: 'tutorial_video_played',
  TUTORIAL_VIDEO_PAUSED: 'tutorial_video_paused',
  TUTORIAL_VIDEO_ENDED: 'tutorial_video_ended',
  TUTORIAL_VIDEO_VOLUME_CHANGED: 'tutorial_video_volume_changed',
  USER_WIPED: 'user_wiped',
  WELCOME_DISCRETIONARY_EXPENSE_SELECTED: 'welcome_discretionary_expenses_selected',
  WELCOME_INCOME_SELECTED: 'welcome_income_selected',
  WELCOME_RECURRING_EXPENSE_SELECTED: 'welcome_recurring_expenses_selected',
  WELCOME_SIGN_UP_CLICKED: 'welcome_sign_up_clicked',
  WELCOME_SIGN_UP_ERROR_CAUGHT: 'welcome_sign_up_error',
  WELCOME_STEP_REACHED: 'welcome_step_reached',
  WELCOME_TIMESCALE_CHANGED: 'welcome_timescale_changed',
});

const metricTracker: { [key: string]: number } = {};

const trackMetric = (name: string, properties?: Properties, options?: CaptureOptions) => {
  if (!name) throw new Error('Name Required');

  if (metricTracker[name] === undefined || metricTracker[name] < Date.now() - BATCH_DELAY_AMOUNT) {
    if (process.env.NODE_ENV === 'production') {
      posthog.capture(name, properties, options);
      logEvent(analytics, `${name} (sent)`, properties);
    }
    metricTracker[name] = Date.now();
    return console.log('trackMetric', `${name}`, properties, options);
  } else {
    return console.log('trackMetric', `${name} (debounced)`, properties, options);
  }
};

const useTrackMetric = (name: string) => {
  useEffect(
    () => trackMetric(name), // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export { Metric, trackMetric, setMetricUser, useTrackMetric, unsetMetricUser, BATCH_DELAY_AMOUNT };
