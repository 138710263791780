import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { BsDatabaseLock, BsSafe2, BsShieldLock } from 'react-icons/bs';
import TutorialVideo from '../TutorialVideo';

export default function PreambleStep() {
  return (
    <>
      <div className="text-center">
        <div className="lead fw-bolder mb-2">Welcome to Spend!</div>

        <p className="mb-0">
          Spend works by creating a secure, read-only connection to your bank. We pride ourselves on our security and privacy and use
          bank-grade protocols to protect your data.
        </p>
      </div>

      <TutorialVideo />

      <ListGroup>
        <ListGroup.Item>
          <div className="text-muted d-flex align-items-center">
            <div style={{ height: 24, width: 24 }} className="me-3 text-center">
              <BsSafe2 size={24} className="me-3" style={{ verticalAlign: 'initial' }} />
            </div>
            <div className="small-font">Data is transferred using Transport Layer Security (TLS).</div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div className="text-muted d-flex align-items-center">
            <div style={{ height: 24, width: 24 }} className="me-3 text-center">
              <BsDatabaseLock size={24} className="me-3" style={{ verticalAlign: 'initial' }} />
            </div>
            <div className="small-font">Data is stored using 256-bit AES encryption.</div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div className="text-muted d-flex align-items-center">
            <div style={{ height: 24, width: 24 }} className="me-3 text-center">
              <BsShieldLock size={24} style={{ verticalAlign: 'initial' }} />
            </div>

            <div className="small-font">We promise never to give or sell your data to third parties.</div>
          </div>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
}
