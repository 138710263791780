import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import HelpfulLabel from '../HelpfulLabel';
import pluralize from '../../shared/pluralize';
import { useDashboard } from '../../contexts/DashboardContext';
import RunwayModal from './RunwayModal';
import formatCurrency from '../../shared/formatCurrency';

function DashboardGauge() {
  const [showRunwayModal, setShowRunwayModal] = useState(false);
  const { runway, budgetView } = useDashboard();

  return (
    <Stack gap={2}>
      <Stack className="text-center">
        <div
          style={{
            transition: 'color 400ms',
            fontSize: '2.2em',
            fontWeight: 'bold',
          }}
          className={`text-${budgetView?.safeToSpend.color || 'muted'}`}
        >
          {budgetView?.safeToSpend.amountFormatted || formatCurrency(0)}
        </div>
        <div>{budgetView?.safeToSpend.label || 'Safe to Spend'}</div>
      </Stack>

      <div
        className="small-font"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ flex: 1 }}>Runway:</div>
        <div>
          <HelpfulLabel onClick={() => setShowRunwayModal(true)}>{pluralize(runway?.runwayInMonths, 'month')}</HelpfulLabel>
        </div>
      </div>

      <RunwayModal show={showRunwayModal} onHide={() => setShowRunwayModal(false)} />
    </Stack>
  );
}

export default DashboardGauge;
