import React from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import Dashboard from '../components/Dashboard/Dashboard';
import { Metric, useTrackMetric } from '../utils/metrics';

// const SECTIONS = ['spending', 'income', 'expenses', 'recoveries', 'goals'];

function DashboardPage() {
  useDocumentTitle('Dashboard');
  useTrackMetric(Metric.DASHBOARD_PAGE_VISITED);

  // const { section } = useParams();

  // // Leaving this in is a no-op because the section param is never set (but we may want to add it in the future)
  // if (section && !SECTIONS.includes(section))
  //   return <Navigate to={generateRoute(Route.Budget, { timescale: timescaleString, date: dateString })} replace />;

  return <Dashboard />;
}

export default DashboardPage;
