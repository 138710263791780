import React, { useState } from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { deleteField, doc, setDoc } from 'firebase/firestore';
import db, { googleSignOut, recalculateBudgets, wipeUser } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { Alert, Badge, Form, Stack } from 'react-bootstrap';
import { useAppLayout } from '../contexts/AppLayoutContext';
import { MONTH_RUNWAY_PREVIOUS_PERIODS, WEEK_RUNWAY_PREVIOUS_PERIODS } from '../shared/constants';
import { HelpSectionId } from './HelpPage';
import { Link, useNavigate } from 'react-router-dom';
import { utcDay } from '../shared/days';
import BetterButton from '../components/BetterButton';
import { Dayjs } from 'dayjs';
import { BsTrash } from 'react-icons/bs';
import { Route } from '../Routes';
import ConfirmButton from '../components/ConfirmButton';
import { Metric } from '../utils/metrics';
import ErrorAlert from '../components/ErrorAlert';
import useUser from '../hooks/useUser';

/**
 * Sets the boolean setting on the User document to true when true is passed in
 * or removes the setting when false is passed in.
 */
async function setIsAllowanceBasedOnMovingAverage(uid: string, value: boolean) {
  const userDocRef = doc(db, 'users', uid);
  const dbValue = value || deleteField();
  await setDoc(userDocRef, { isAllowanceBasedOnMovingAverage: dbValue }, { merge: true });
}

async function changeCarryoverStartsAt(uid: string, value: Dayjs, recalculateFrom: Date) {
  const userDocRef = doc(db, 'users', uid);
  await setDoc(userDocRef, { carryoverStartsAt: value.toDate() }, { merge: true });
  recalculateBudgets(recalculateFrom);
}

function SettingsPage() {
  useDocumentTitle('Settings');
  const navigate = useNavigate();
  const auth = useAuth(); // avoid deconstruction to prevent errors during account deletion
  const { user } = useUser();
  const { setToast } = useAppLayout();

  const [isWipingUser, setIsWipingUser] = useState(false);
  const [wipeUserError, setWipeUserError] = useState<Error | null>();
  const [newCarryoverStartsAt, setNewCarryoverStartsAt] = useState<string | null>(null);

  async function handleWipeUser() {
    setIsWipingUser(true);
    try {
      await wipeUser();
      await googleSignOut();
      navigate(Route.Root);
    } catch (error) {
      setWipeUserError(error);
    } finally {
      setIsWipingUser(false);
    }
  }

  return (
    <>
      <h3 className="mb-4">Settings</h3>
      <Stack gap={2}>
        {auth && user && !user.onboardingStep && (
          <>
            <Alert variant="warning">Be advised: changing these settings may dramatically change how you track your spending.</Alert>

            <section>
              <h4>Safe to Spend</h4>

              <p>Configure how your monthly and weekly allowance Safe to Spend is determined for your Budget.</p>

              <Form.Check
                checked={!user.isAllowanceBasedOnMovingAverage}
                id="allowance-mode-discretionary-income"
                label={
                  <>
                    <div>
                      <strong>Net income-based (default)</strong>
                    </div>
                    <div className="small-font">Your recurring income minus recurring expenses, plans, and carryover</div>
                  </>
                }
                name="allowance-mode"
                onChange={() => {
                  setIsAllowanceBasedOnMovingAverage(auth.uid, false);
                  setToast('Setting saved!');
                }}
                type="radio"
              />
              <Form.Check
                checked={user.isAllowanceBasedOnMovingAverage}
                id="allowance-mode-moving-average"
                label={
                  <>
                    <Stack gap={2} direction="horizontal">
                      <strong>Average spending-based</strong>
                      <Badge>Beta</Badge>
                    </Stack>

                    <div className="small-font">
                      Based on a {WEEK_RUNWAY_PREVIOUS_PERIODS}-week or {MONTH_RUNWAY_PREVIOUS_PERIODS}-month moving average of your
                      spending (note this calculation does not include recurring income, recurring expenses, plans, or carryover)
                    </div>
                  </>
                }
                name="allowance-mode"
                onChange={() => {
                  setIsAllowanceBasedOnMovingAverage(auth.uid, true);
                  setToast('Setting saved!');
                }}
                type="radio"
              />
            </section>

            <hr />

            <section>
              <h4>Carryover</h4>

              <p>
                Any over/under-spending from the previous week or month gets applied to this week’s or month’s Safe to Spend (
                <Link to={`/help#${HelpSectionId.RecurringExpenses}`}>learn more</Link>). Carryover first went into effect when you signed
                up on {utcDay(user?.createdAt).format('MMM D, YYYY')}.
              </p>

              <Stack gap={2} direction="horizontal">
                <Form.Control
                  type="date"
                  value={newCarryoverStartsAt || (user.carryoverStartsAt && utcDay(user.carryoverStartsAt.toDate()).format('YYYY-MM-DD'))}
                  onChange={(event) => {
                    setNewCarryoverStartsAt(event.target.value);
                  }}
                />
                <BetterButton
                  variant="outline-primary"
                  disabled={!newCarryoverStartsAt}
                  onClick={() => {
                    const utcDayNewCarryoverStartsAt = utcDay(newCarryoverStartsAt);
                    let recalculateFrom = utcDayNewCarryoverStartsAt.toDate();

                    if (user.carryoverStartsAt) {
                      const carryoverStartsAtDate = user.carryoverStartsAt.toDate();
                      if (utcDayNewCarryoverStartsAt.isAfter(carryoverStartsAtDate)) recalculateFrom = carryoverStartsAtDate;
                    }

                    changeCarryoverStartsAt(auth.uid, utcDayNewCarryoverStartsAt, recalculateFrom);
                    setToast(`Carryover set to take effect on ${utcDayNewCarryoverStartsAt.format('MMM D, YYYY')}.`);
                  }}
                >
                  Set start date
                </BetterButton>
              </Stack>
            </section>
            <hr />
          </>
        )}
        <section>
          <h4>Account deletion</h4>
          {wipeUserError && <ErrorAlert error={wipeUserError} />}
          <p>
            This will disconnect Spend from all of your financial institutions and delete all transaction, account, balance, and personal
            data from our system. This action cannot be undone. If you wish to use Spend again in the future, you will need to sign up again
            and re-connect your accounts.
          </p>
          <ConfirmButton
            isLoading={isWipingUser}
            metric={Metric.USER_WIPED}
            variant="outline-danger"
            beforeIcon={<BsTrash />}
            onConfirm={handleWipeUser}
          >
            Delete my Spend account
          </ConfirmButton>
        </section>
      </Stack>
    </>
  );
}

export default SettingsPage;
