import React, { useState } from 'react';
import { Badge, Col, Form, ModalProps, Row, Spinner, Stack } from 'react-bootstrap';
import BetterButton from './BetterButton';
import { TransactionDocData } from '../types';
import { utcDay, utcDayWithSameTime } from '../shared/days';
import { useAuth } from '../contexts/AuthContext';
import { updateTransaction } from '../utils/transaction';
import { useAppLayout } from '../contexts/AppLayoutContext';
import ErrorAlert from './ErrorAlert';
import TransactionName from './TransactionName';
import BetterModal from './BetterModal';
import truncate from '../shared/truncate';
import TransactionPending from './TransactionPending';
import AccountMask from './AccountMask';
import useAccounts from '../hooks/useAccounts';
import { BsBank2, BsTag } from 'react-icons/bs';
import TransactionAmountButton from './TransactionAmountButton';

interface TransactionModalFormProps extends ModalProps {
  transaction: TransactionDocData;
}

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_FORMAT_DISPLAY = 'MM/DD/YYYY';
const MIN_MAX_LIMIT_VALUE = 30;
const MIN_MAX_LIMIT_UNIT = 'days';

/**
 * Test cases:
 * - dateOriginal should be set with time if changed
 * - nameOriginal should be set if changed
 * - dateOriginal should be cleared if set back to original
 * - nameOriginal should be cleared if set back to original
 * - date picker should be constrained within 30 days of original date
 * - typing in date outside the 30 day constraint should render an error
 * - original name should display if set
 * - original date should display if set
 */
export default function TransactionModalForm({ transaction, ...rest }: TransactionModalFormProps) {
  const { uid } = useAuth();
  const { setToast } = useAppLayout();

  const [name, setName] = useState(transaction.name);
  const [nameIsValid, setNameIsValid] = useState(true);
  const [date, setDate] = useState(utcDay(transaction.date.toDate()).format(DATE_FORMAT));
  const [dateIsValid, setDateIsValid] = useState(true); // Do we really need this? What are invalid dates?
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [show, setShow] = useState(true);

  const { accountsById, isLoading: isAccountsLoading } = useAccounts();

  const dateOriginal = utcDay((transaction.dateOriginal || transaction.date).toDate());
  const nameOriginal = transaction.nameOriginal || transaction.name;
  const formHasChanged = name !== transaction.name || date !== utcDay(transaction.date.toDate()).format(DATE_FORMAT);
  const transactionHasChanged = transaction.nameOriginal || transaction.dateOriginal;

  const endOfWeek = utcDay().endOf('week');
  const minDate = dateOriginal.subtract(MIN_MAX_LIMIT_VALUE, MIN_MAX_LIMIT_UNIT).startOf('week');
  let maxDate = dateOriginal.add(MIN_MAX_LIMIT_VALUE, MIN_MAX_LIMIT_UNIT).endOf('week');
  if (maxDate.isAfter(endOfWeek)) maxDate = endOfWeek;

  async function handleUpdate() {
    setIsLoading(true);

    const newNameIsValid = name.length > 0;

    const utcDate = utcDayWithSameTime(date, transaction.date.toDate());
    const newDateIsValid = utcDate.isValid() && utcDate.isSameOrAfter(minDate) && utcDate.isSameOrBefore(maxDate);

    setNameIsValid(newNameIsValid);
    setDateIsValid(newDateIsValid);

    if (!newNameIsValid || !newDateIsValid) {
      setIsLoading(false);
      return;
    }

    try {
      await updateTransaction(uid, transaction, {
        name,
        date: utcDate,
      });
    } catch (error) {
      setError(error);
      return;
    } finally {
      setIsLoading(false);
    }

    setShow(false);
    setToast('Transaction updated.');
  }

  function handleNameChange(event) {
    setName(event.target.value);
    setNameIsValid(true);
  }

  function handleDateChange(event) {
    setDate(event.target.value);
    setDateIsValid(true);
  }

  async function handleRevert() {
    setIsLoading(true);

    try {
      await updateTransaction(uid, transaction, {
        name: nameOriginal,
        date: dateOriginal,
      });
    } catch (error) {
      setError(error);
      return;
    } finally {
      setIsLoading(false);
    }

    setShow(false);
    setToast('Transaction reverted.');
  }

  return (
    <BetterModal
      title={truncate(transaction.name, 30)}
      show={show}
      onHide={() => setShow(false)}
      footer={
        <>
          <div>
            {transactionHasChanged && (
              <BetterButton variant="outline-danger" hideLoadingIndicators isLoading={isLoading} onClick={handleRevert}>
                Revert
              </BetterButton>
            )}
          </div>
          <div>
            <Stack gap={2} direction="horizontal">
              <BetterButton type="submit" disabled={!formHasChanged} isLoading={isLoading} onClick={handleUpdate}>
                Update
              </BetterButton>
            </Stack>
          </div>
        </>
      }
      {...rest}
    >
      <ErrorAlert error={error} />

      <Form onSubmit={handleUpdate} noValidate>
        <Form.Group className="mb-3">
          {transaction.pending && (
            <Badge bg="secondary">
              <TransactionPending />
            </Badge>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="plan-name" className="visually-hidden">
            Name
          </Form.Label>
          <Form.Control
            required
            isInvalid={!nameIsValid}
            type="text"
            value={name}
            onChange={handleNameChange}
            autoComplete="off"
            id="transaction-name"
          />
          <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>

          {transaction.nameOriginal && (
            <Form.Text className="mt-2 d-block">
              Original: <TransactionName id={transaction.transactionId} name={transaction.nameOriginal} />
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Row>
            <Col xs={7}>
              <Form.Label htmlFor="plan-name" className="visually-hidden">
                Date
              </Form.Label>
              <Form.Control
                required
                isInvalid={!dateIsValid}
                type="date"
                value={date}
                onChange={handleDateChange}
                autoComplete="off"
                id="transaction-date"
                min={minDate.format(DATE_FORMAT)}
                max={maxDate.format(DATE_FORMAT)}
              />
              <Form.Control.Feedback type="invalid">
                Must be between {minDate.format(DATE_FORMAT_DISPLAY)} and {maxDate.format(DATE_FORMAT_DISPLAY)}.
              </Form.Control.Feedback>
            </Col>

            <Col xs={5} className="pt-1 text-end">
              <TransactionAmountButton transaction={transaction} />
            </Col>
          </Row>

          {transaction.dateOriginal && <Form.Text className="mt-2 d-block">Original: {dateOriginal.format(DATE_FORMAT_DISPLAY)}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-2">
          <Stack direction="horizontal" gap={2} className="align-items-center">
            <div>
              <BsTag className="d-block text-secondary" />
            </div>
            <div>{transaction.category.join(' / ')}</div>
          </Stack>
        </Form.Group>
        <Form.Group>
          <Stack direction="horizontal" gap={2} className="align-items-center">
            <div>
              <BsBank2 className="d-block text-secondary" />
            </div>
            <div>{isAccountsLoading ? <Spinner size="sm" /> : <AccountMask account={accountsById[transaction.accountId]} />}</div>
          </Stack>
        </Form.Group>
      </Form>
    </BetterModal>
  );
}
