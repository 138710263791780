import React from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import Onboarding from '../components/Onboarding';
import { useNavigate } from 'react-router-dom';
import { Route } from '../Routes';
import useUser from '../hooks/useUser';
import { useDeepCompareEffect } from 'use-deep-compare';

export default function OnboardingPage() {
  useDocumentTitle('Onboarding');

  const { user } = useUser();
  const navigate = useNavigate();

  useDeepCompareEffect(() => {
    if (user && !user.onboardingStep) navigate(Route.Root);
  }, [user]);

  return <Onboarding />;
}
