import { useState } from 'react';
import { collection, limit, orderBy, query, QueryConstraint, FirestoreError, where } from 'firebase/firestore';
import db from '../firebase';
import useOnSnapshot from '../hooks/useOnSnapshot';
import { useAuth } from '../contexts/AuthContext';
import { Dayjs } from 'dayjs';
import { BalanceSheetDocData } from '../types';

interface UseBalanceSheetOptions {
  createdOnOrBefore?: Dayjs;
}

export default function useBalanceSheet({ createdOnOrBefore }: UseBalanceSheetOptions = {}) {
  const { uid } = useAuth();
  const [error, setError] = useState<FirestoreError>();

  const queryConstraints: QueryConstraint[] = [orderBy('createdAt', 'desc'), limit(1)];

  if (createdOnOrBefore) queryConstraints.push(where('createdAt', '<=', createdOnOrBefore.toDate()));

  const { docs, isLoading } = useOnSnapshot<BalanceSheetDocData>(
    query(collection(db, 'users', uid, 'balanceSheets'), ...queryConstraints),
    {
      queryKey: createdOnOrBefore?.valueOf()?.toString(),
      onError: setError,
    },
  );

  return {
    error,
    isLoading,
    balanceSheet:
      docs[0] ||
      ({
        assetBalancesByAccountId: {},
        liabilityBalancesByAccountId: {},
        createdForPlaidItemId: '',
      } as BalanceSheetDocData),
  };
}
