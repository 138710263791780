import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { Stack, Toast, ToastContainer } from 'react-bootstrap';
import { BsArrowRepeat, BsEyeSlash, BsSearch } from 'react-icons/bs';
import { collection, FirestoreError, query } from 'firebase/firestore';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import Header from '../components/Header';
import { useAuth } from '../contexts/AuthContext';
import useScopeStyles from '../hooks/useScopeStyles';
import {
  IgnoredTransactionsExplanation,
  RecoveryExplanation,
  RecurringExpensesExplanation,
  RecurringIncomesExplanation,
  SafeToSpendExplanation,
} from '../pages/HelpPage';
import useOnSnapshot from '../hooks/useOnSnapshot';
import useAppSettings from '../hooks/useAppSettings';
import db from '../firebase';
import Maintenance from '../components/Maintenance';
import DemoDataNotice from '../components/DemoDataNotice';
import { Metric, trackMetric } from '../utils/metrics';
import useUser from '../hooks/useUser';
import BetterModal from '../components/BetterModal';
import { PlaidItemForUpdateDocData, PlanDocData, TransactionDocData } from '../types';
import { LABELS } from '../shared/constants';
import Transactions from '../components/Transactions';
import PlanModal from '../components/Plans/PlanModal';
import BudgetCalendar, { Timescale } from '../shared/BudgetCalendar';
import { utcDay } from '../shared/days';
import AppLayoutContext from '../contexts/AppLayoutContext';
import TransactionModalForm from '../components/TransactionModalForm';

const ExplainerModalId = Object.freeze({
  Essentials: 'essentials',
  IgnoredTransactions: 'ignored-transactions',
  Recover: 'recover',
  RecurringIncomes: 'recurring-incomes',
  SafeToSpend: 'Safe to Spend',
});

const mediaQuerylist = window.matchMedia
  ? window.matchMedia('(prefers-color-scheme: dark)')
  : { matches: false, addEventListener: () => {} };

function AppLayout() {
  useScopeStyles('app');

  const { uid } = useAuth();
  const { appSettings } = useAppSettings();
  const { user } = useUser();

  const [toast, setToast] = useState<string>();
  const [explainerModalId, setExplainerModalId] = useState<string>();
  const [isDarkMode, setIsDarkMode] = useState(mediaQuerylist.matches);
  const [plaidItemsForUpdateOnSnapshotError, setPlaidItemsForUpdateOnSnapshotError] = useState<FirestoreError>();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [plan, setPlan] = useState<PlanDocData>();
  const [transaction, setTransaction] = useState<TransactionDocData>();

  const {
    docs: plaidItemsForUpdate,
    subscribe: plaidItemsForUpdateSnapshotSubscribe,
    unsubscribe: plaidItemsForUpdateSnapshotUnsubscribe,
    isLoading: isLoadingPlaidItemsForUpdate,
  } = useOnSnapshot<PlaidItemForUpdateDocData>(query(collection(db, 'users', uid, 'plaidItemsForUpdate')), {
    onError: setPlaidItemsForUpdateOnSnapshotError,
  });

  useEffect(() => {
    const html = document.querySelector('html');
    html.setAttribute('data-bs-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  useEffect(() => {
    mediaQuerylist.addEventListener('change', ({ matches }: MediaQueryListEvent) => setIsDarkMode(matches));
  }, []);

  const openExplainerModal = useCallback(
    (id: SetStateAction<string>) => {
      setExplainerModalId(id);
      trackMetric(Metric.EXPLAINER_MODAL_OPENED, { explainerModalId: id });
    },
    [setExplainerModalId],
  );

  const hideExplainerModal = useCallback(() => {
    setExplainerModalId(undefined);
    trackMetric(Metric.EXPLAINER_MODAL_CLOSED, { explainerModalId });
  }, [setExplainerModalId]);
  const openPlanModal = useCallback((plan) => setPlan(plan), [setPlan]);
  const openTransactionModal = useCallback((transaction) => setTransaction(transaction), [setTransaction]);
  const openSearchModal = useCallback(() => setShowSearchModal(true), [setShowSearchModal]);

  const { date: dateParam, timescale: timescaleParam } = useParams();

  // Param validation: redirect back to the default view (the current week) if the parameters are invalid
  if (dateParam && !dateParam.match(/^\d{4}-\d{2}-\d{2}$/)) return <Navigate to="/" replace />;
  if (timescaleParam && timescaleParam !== Timescale.Month && timescaleParam !== Timescale.Week) return <Navigate to="/" replace />;

  const timescale = timescaleParam && timescaleParam === Timescale.Month ? Timescale.Month : Timescale.Week;
  const budgetPeriods = BudgetCalendar.getBudgetPeriodsByDate(timescale, dateParam ? utcDay(dateParam) : utcDay());

  if (appSettings.isMaintenanceMode) return <Maintenance />;

  return (
    <AppLayoutContext.Provider
      value={{
        budgetPeriods,
        isDarkMode,
        isLoadingPlaidItemsForUpdate,
        openExplainerModal,
        openPlanModal,
        openSearchModal,
        openTransactionModal,
        plaidItemsForUpdate,
        plaidItemsForUpdateOnSnapshotError,
        plaidItemsForUpdateSnapshotSubscribe,
        plaidItemsForUpdateSnapshotUnsubscribe,
        setIsDarkMode,
        setToast,
        timescale,
        toast,
      }}
    >
      <Header />

      <main>
        {user && user.demoData && !user.onboardingStep && <DemoDataNotice />}

        <Outlet />
      </main>

      {plan && <PlanModal plan={plan} onExited={() => setPlan(undefined)} />}
      {transaction && <TransactionModalForm transaction={transaction} onExited={() => setTransaction(undefined)} />}

      <BetterModal title="Search" show={showSearchModal} icon={BsSearch} onHide={() => setShowSearchModal(false)}>
        <Transactions />
      </BetterModal>

      <BetterModal
        icon={BsArrowRepeat}
        title="Recurring income"
        show={explainerModalId === ExplainerModalId.RecurringIncomes}
        onHide={hideExplainerModal}
      >
        <Stack gap={3}>
          <RecurringIncomesExplanation onLinkClick={hideExplainerModal} />
        </Stack>
      </BetterModal>

      <BetterModal
        icon={BsArrowRepeat}
        title={LABELS.RECURRING_EXPENSES}
        show={explainerModalId === ExplainerModalId.Essentials}
        onHide={hideExplainerModal}
      >
        <Stack gap={3}>
          <RecurringExpensesExplanation onLinkClick={hideExplainerModal} />
        </Stack>
      </BetterModal>

      <BetterModal title="Safe to Spend" show={explainerModalId === ExplainerModalId.SafeToSpend} onHide={hideExplainerModal}>
        <Stack gap={3}>
          <SafeToSpendExplanation />
        </Stack>
      </BetterModal>

      <BetterModal
        title="Ignored transactions"
        show={explainerModalId === ExplainerModalId.IgnoredTransactions}
        onHide={hideExplainerModal}
        icon={BsEyeSlash}
      >
        <Stack gap={3}>
          <IgnoredTransactionsExplanation onLinkClick={hideExplainerModal} />
        </Stack>
      </BetterModal>

      <BetterModal title="Recover" show={explainerModalId === ExplainerModalId.Recover} onHide={hideExplainerModal}>
        <Stack gap={3}>
          <RecoveryExplanation onLinkClick={hideExplainerModal} />
        </Stack>
      </BetterModal>

      <ToastContainer containerPosition="fixed" style={{ marginBottom: 24 }} position="bottom-center">
        <Toast show={!!toast} onClose={() => setToast(null)} delay={7000} autohide bg={isDarkMode ? 'light' : 'dark'}>
          <Toast.Body className={isDarkMode ? 'text-dark' : 'text-light'}>{toast}</Toast.Body>
        </Toast>
      </ToastContainer>
    </AppLayoutContext.Provider>
  );
}

export default AppLayout;
export { ExplainerModalId };
