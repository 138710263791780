import React, { useCallback, useState, useRef } from 'react';
import { Metric, trackMetric } from '../utils/metrics';

export default function TutorialVideo() {
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const trackVideoMetric = useCallback((metric: string, event: React.SyntheticEvent<HTMLVideoElement>) => {
    const video = event.currentTarget;
    trackMetric(metric, { time: video.currentTime, volume: video.volume, muted: video.muted });
  }, []);

  return (
    <div
      className="w-100 rounded-3 overflow-hidden"
      style={{ position: 'relative', paddingTop: '56.25%' /* 16:9 aspect ratio */, backgroundColor: 'black' }}
    >
      <img
        className={`position-absolute w-100${isLoading ? '' : ' visually-hidden'}`}
        src="https://storage.googleapis.com/spend-tutorial-videos/basics-427.jpg"
        srcSet={[427, 853, 1280, 1920]
          .map((width) => `https://storage.googleapis.com/spend-tutorial-videos/basics-${width}.jpg ${width}w`)
          .join(', ')}
        sizes="(max-width: 427px) 427w, (max-width: 853px) 853w, (max-width: 1280px) 1280w, 1920w"
        alt="A video preview"
        style={{ top: 0, left: 0, width: '100%', height: '100%' }}
      />
      <video
        ref={videoRef}
        controls
        autoPlay
        muted
        playsInline
        style={{ top: 0, left: 0, width: '100%', height: '100%' }}
        className={`position-absolute w-100${isLoading ? ' visually-hidden' : ''}`}
        onLoadedData={(event) => {
          trackVideoMetric(Metric.TUTORIAL_VIDEO_LOADED, event);
          setIsLoading(false);
        }}
        onPlay={(event) => trackVideoMetric(Metric.TUTORIAL_VIDEO_PLAYED, event)}
        onPause={(event) => trackVideoMetric(Metric.TUTORIAL_VIDEO_PAUSED, event)}
        onEnded={(event) => trackVideoMetric(Metric.TUTORIAL_VIDEO_ENDED, event)}
        onVolumeChange={(event) => trackVideoMetric(Metric.TUTORIAL_VIDEO_VOLUME_CHANGED, event)}
      >
        <source src="https://storage.googleapis.com/spend-tutorial-videos/basics-480p.webm" type="video/webm" media="(max-width: 853px)" />
        <source src="https://storage.googleapis.com/spend-tutorial-videos/basics-480p.mp4" type="video/mp4" media="(max-width: 853px)" />
        <source src="https://storage.googleapis.com/spend-tutorial-videos/basics-720p.webm" type="video/webm" media="(max-width: 1280px)" />
        <source src="https://storage.googleapis.com/spend-tutorial-videos/basics-720p.mp4" type="video/mp4" media="(max-width: 1280px)" />
        <source
          src="https://storage.googleapis.com/spend-tutorial-videos/basics-1080p.webm"
          type="video/webm"
          media="(min-width: 1281px)"
        />
        <source src="https://storage.googleapis.com/spend-tutorial-videos/basics-1080p.mp4" type="video/mp4" media="(min-width: 1281px)" />
      </video>
    </div>
  );
}
