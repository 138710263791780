import React, { useState } from 'react';
import BetterButton from '../components/BetterButton';
import { Alert } from 'react-bootstrap';
import db from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Route } from '../Routes';
import ErrorAlert from './ErrorAlert';

export default function DemoDataNotice() {
  const { uid } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const userDocRef = doc(db, 'users', uid);

  async function handleConnectAccounts() {
    setIsLoading(true);

    try {
      await updateDoc(userDocRef, {
        onboardingStep: 'add-accounts',
        updatedAt: serverTimestamp(),
      });

      navigate(Route.Onboarding, { replace: true });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ErrorAlert error={error} onClose={() => setError(null)} />

      <Alert>
        <div className="d-sm-flex small-font align-items-center justify-content-between">
          <div className="mb-3 mb-sm-0 me-sm-3">
            You’re currently using Spend with demo data. For a personalized budget based on your finances, connect an account.
          </div>
          <div>
            <BetterButton isLoading={isLoading} size="sm" onClick={handleConnectAccounts}>
              Connect an account
            </BetterButton>
          </div>
        </div>
      </Alert>
    </>
  );
}
