import { Navigate, Route as RouterRoute, Routes as RouterRoutes } from 'react-router-dom';
import AppLayout from '../layouts/AppLayout';
import HelpPage from '../pages/HelpPage';
import PrivacyPage from '../pages/PrivacyPage';
import SecurityPage from '../pages/SecurityPage';
import AboutUsPage from '../pages/AboutUsPage';
import HomePage from '../pages/HomePage';
import SettingsPage from '../pages/SettingsPage';
import DashboardPage from '../pages/DashboardPage';
import OnboardingPage from '../pages/OnboardingPage';
import AccountsPage from '../pages/AccountsPage';
import ImpersonationPage from '../pages/ImpersonationPage';
import UserInterfacePage from '../pages/UserInterfacePage';
import React from 'react';
import { RequireAuth, Route } from '../Routes';
import useUser from '../hooks/useUser';
import BigSpinner from '../components/BigSpinner';

function RequireOnboarding({ children }) {
  const { user, isLoading: isUserLoading } = useUser();

  if (isUserLoading || !user) return <BigSpinner />;
  if (user.onboardingStep) return <Navigate to={Route.Onboarding} replace />;
  return <>{children}</>;
}

function RequireRealData({ children }) {
  const { user, isLoading: isUserLoading } = useUser();

  if (isUserLoading || !user) return <BigSpinner />;
  if (user?.demoData) return <Navigate to={Route.Root} replace />;
  return <>{children}</>;
}

export default function AppRoutes() {
  return (
    <RouterRoutes>
      <RouterRoute element={<AppLayout />}>
        <RouterRoute path={Route.Help} element={<HelpPage />} />
        <RouterRoute path={Route.Privacy} element={<PrivacyPage />} />
        <RouterRoute path={Route.Security} element={<SecurityPage />} />
        <RouterRoute path={Route.AboutUs} element={<AboutUsPage />} />
        <RouterRoute
          path={Route.Settings}
          element={
            <RequireAuth otherwise={<HomePage />}>
              <SettingsPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Root}
          element={
            <RequireAuth otherwise={<HomePage />}>
              <RequireOnboarding>
                <DashboardPage />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Onboarding}
          element={
            <RequireAuth>
              <OnboardingPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Accounts}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <RequireRealData>
                  <AccountsPage />
                </RequireRealData>
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Impersonate}
          element={
            <RequireAuth>
              <ImpersonationPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.UserInterface}
          element={
            <RequireAuth>
              <UserInterfacePage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Budget}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <DashboardPage />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path="/:timeline/:date/:section"
          element={
            <RequireAuth>
              <RequireOnboarding>
                <DashboardPage />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
      </RouterRoute>
    </RouterRoutes>
  );
}
