import { useAuth } from '../contexts/AuthContext';
import db from '../firebase';
import useOnSnapshot from './useOnSnapshot';
import { useState } from 'react';
import { collection, DocumentData, FirestoreError, query } from 'firebase/firestore';
import { PlanDocData } from '../types';

export default function usePlans(callback?: (snapshot: DocumentData) => void) {
  const { uid } = useAuth();
  const [error, setError] = useState<FirestoreError>();

  const plansQuery = query(collection(db, 'users', uid, 'plans'));

  const { docs: plans, isLoading } = useOnSnapshot<PlanDocData>(plansQuery, {
    onError: setError,
    onSnapshot: callback,
  });

  return {
    error,
    isLoading,
    plans,
    goals: plans.filter((plan) => !plan.transactionId),
    recoveries: plans.filter((plan) => !!plan.transactionId),
    plansById: plans.reduce((plansObject: { [key: string]: PlanDocData }, doc) => {
      plansObject[doc.__id] = doc;
      return plansObject;
    }, {}),
  };
}
